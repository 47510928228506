import { TvvCoreAppConfig } from '../../pages/_app';
import { Dict } from '@chakra-ui/utils';
import { NewComponentTheme } from '../theme';

/**
 * https://themera.vercel.app/
 * https://smart-swatch.netlify.app/
 */
export const theme: Dict = {
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Rubik, serif',
  },
  shadows: {
    brand: '#58b300 0 0 0 2px',
  },
  colors: {
    headerColor: '#58b300',
    footerColor: '#58b300',
    textColor: '#58b300',
    textColorBase: '#555',
    starColor: '#58b300',
    brand: {
      50: '#efffdd',
      100: '#d6ffb0',
      200: '#bdff7f',
      300: '#a4ff4d',
      400: '#8bff1e',
      500: '#72e607',
      600: '#58b300',
      700: '#3e8000',
      800: '#244d00',
      900: '#091b00',
    },
    searchbar: {
      borderColor: '#3e8000',
      buttonBg: '#3e8000',
      iconButtonTextColor: '#58b300',
    },
  },
  components: {
    ...NewComponentTheme,
  },
};

const config: TvvCoreAppConfig = {
  gaId: 'G-TNKL9FRT8N',
  newLayout: {
    footer: {
      addons: 'waves',
    },
  },
  usesNewLayout: true,
  fullUrl: 'https://www.ebikeatlas.de',
  siteName: 'eBikeAtlas.de',
  frontendId: 'ebikeatlas',
  frontendName: 'eBikeAtlas',
  menuMain: 'ebikeatlas-main',
  menuFooter: 'ebikeatlas-footer',
  menuFooterMain: 'ebikeatlas-footer-main',
  embedEnabled: false,
  matomoId: '2',
  renderConfig: {
    activity: {
      renderSidebarAdTeaser: true,
      nearHotels: false,
      nearActivities: false,
      nearPois: true,
      hotelActivities: true,
      hotelActivitiesOther: false,
    },
    hotel: {
      nearActivities: false,
      nearPois: true,
      renderSidebarAdTeaser: false,
    },
    poi: {
      nearHotels: true,
      nearActivities: true,
      nearPois: true,
      renderSidebarAdTeaser: true,
    },
    freizeithotelsTeaser: true,
  },
  titles: {
    hotel: {
      activities: 'eBike-Touren des Hotels',
      furterActivities: 'Weitere eBike-Touren des Hotels',
    },
    activity: {
      hotel: 'Empfohlenes Hotel',
    },
  },
};

export default config;
