import { gql } from '@apollo/client';
import { OfferForFrontendItemFragment } from '../../Paragraph/HotelOffers/fragment';

const HotelFeatureFragment = gql`
  fragment HotelFeatureFragment on TermHotelFeature {
    id
    name
    icon
    category {
      id
      name
    }
  }
`;

export const HotelMapTeaserFragment = gql`
  fragment HotelMapTeaserFragment on Hotel {
    id
    url
    category {
      key
    }
    location {
      lat
      lng
    }
  }
`;

export const HotelTeaserFragment = gql`
  fragment HotelTeaserFragment on Hotel {
    id
    url
    title
    minPrice
    averageRating {
      total
      ratingCount
    }
    detailsForFrontend(frontend: $frontend) {
      body
    }
    city
    country {
      name
    }
    location {
      lat
      lng
    }
    region {
      name
    }
    category {
      ...TermCategoryFragment
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      teaserTextSmall: imageStyle(name: IMAGE_TEXT_TEASER_SMALL) {
        url
      }
    }
  }
`;

export const HotelFragment = gql`
  fragment HotelFragment on Hotel {
    id
    url
    fullUrl(frontend: $frontend)
    facebookPageUrl
    onlineBookingUrl
    title
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    category {
      ...TermCategoryFragment
    }
    activities {
      ...ActivityTeaserFragment
    }
    activitiesFreizeithotels {
      ...ActivityTeaserFragment
    }
    pois {
      ...PoiTeaserFragment
    }
    old_id
    beds
    detailsForFrontend(frontend: $frontend) {
      body
      images {
        ...MediaImageFragment
        full: imageStyle(name: FULL) {
          url
        }
        big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
          url
        }
        medium: imageStyle(name: PAGE_HEADER_MEDIUM_THUMB) {
          url
        }
        tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
          url
        }
        small: imageStyle(name: PAGE_HEADER_SMALL_THUMB) {
          url
        }
        teaser: imageStyle(name: TEASER) {
          url
        }
        teaserHuge: imageStyle(name: TEASER_HUGE) {
          url
        }
        teaserSquare: imageStyle(name: TEASER_SQUARE) {
          url
        }
      }
    }
    email
    fax
    highlightedFeaturesForFrontend(frontend: $frontend) {
      ...HotelFeatureFragment
    }
    featuresForFrontend(frontend: $frontend) {
      ...HotelFeatureFragment
    }
    onlyShowOnlineBooking
    company
    frontends {
      name
    }
    homepage
    kns
    country {
      id
      name
      isocode
    }
    location {
      lat
      lng
    }
    lastname
    city
    postcode
    region {
      id
      name
    }
    stars
    street
    teaserImage {
      ...MediaImageFragment
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
      full: imageStyle(name: FULL) {
        url
      }
      big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
        url
      }
      medium: imageStyle(name: PAGE_HEADER_MEDIUM_THUMB) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      small: imageStyle(name: PAGE_HEADER_SMALL_THUMB) {
        url
      }
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserHuge: imageStyle(name: TEASER_HUGE) {
        url
      }
      teaserSquare: imageStyle(name: TEASER_SQUARE) {
        url
      }
    }
    testimonialImage {
      ...MediaImageFragment
      big: imageStyle(name: HERO_LARGE) {
        url
      }
    }
    phone
    firstname
    rooms {
      roomType {
        name
      }
      roomCount
      prices {
        description
        price
        date {
          value
          end_value
        }
      }
    }
    offersForFrontend(frontend: $frontend) {
      ...OfferForFrontendItemFragment
    }
    ratings(offset: 0, limit: 5) {
      total
      items {
        ...RatingFragment
      }
    }
    pushedRatings: ratings(limit: 1, offset: 0, onlyPushed: true) {
      total
      items {
        ...RatingFragment
      }
    }
    nearActivities(frontend: $frontend, limit: 3) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }
    nearPois(frontend: $frontend, limit: 3) {
      total
      items {
        ...PoiTeaserFragment
      }
    }
    averageRating {
      ratingCount
      total
      catering
      housing
      kindness
    }
    maxRating
    activitiesInRegion(frontend: $frontend, filter: $activitiesInRegionFilter) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }
    productsInRegion {
      total
      items {
        ...ProductTeaserFragment
      }
    }

    poisInRegion(frontend: $frontend) {
      total
      items {
        ...PoiTeaserFragment
      }
    }
  }

  ${HotelFeatureFragment}
  ${OfferForFrontendItemFragment}
`;
