import { gql } from '@apollo/client';

export const ParagraphImageAndTextFragment = gql`
  fragment ParagraphImageAndTextFragment on ParagraphImageAndText {
    image {
      ...MediaImageFragment
      teaser: imageStyle(name: INLINE_WITH_TEXT) {
        url
      }
    }
    body
    imagePosition
  }
`;
