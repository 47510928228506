import { gql } from '@apollo/client';
import {
  PoiMapTeaserFragment,
  PoiTableFragment,
} from '../../Node/Poi/fragment';

export const ParagraphPassMapFragment = gql`
  fragment ParagraphPassMap on ParagraphPassMap {
    id
    data(parameters: $passMapParameters) {
      total
      items {
        ...PoiMapTeaserFragment
      }
    }
  }

  ${PoiMapTeaserFragment}
`;
