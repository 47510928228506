import { gql } from '@apollo/client';

export const ParagraphHeroFragment = gql`
  fragment ParagraphHeroFragment on ParagraphHero {
    title
    subtitle
    useNegativeLogoVersion
    image {
      ...MediaImageFragment
      hero: imageStyle(name: HERO_IMAGE) {
        url
      }
    }
    categories {
      id
      title
      category {
        ...TermCategoryFragment
      }
    }
  }
`;
