import { gql } from '@apollo/client';

export const MenuFragment = gql`
  fragment MenuFragment on Menu {
    name
    items {
      title
      url(frontend: $frontend)
      children {
        title
        url(frontend: $frontend)
        children {
          title
          url(frontend: $frontend)
        }
      }
    }
  }
`;
