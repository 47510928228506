import { gql } from '@apollo/client';
import { ParagraphAdSlotFragment } from '../AdSlot/fragment';
import { ParagraphAuthorsFragment } from '../Authors/fragment';
import { ParagraphPartnerFragment } from '../Partner/fragment';

export const ParagraphColumnFragment = gql`
  fragment ParagraphColumnFragment on ParagraphColumn {
    content {
      ... on ParagraphInterface {
        id
      }
      ...ParagraphTextFragment
      ...ParagraphImageTextTeaserFragment
      ...ParagraphImageFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphLinksFragment
      ...ParagraphAuthors
      ...ParagraphPartner
      ...ParagraphAdSlot
    }
  }

  ${ParagraphPartnerFragment}
  ${ParagraphAuthorsFragment}
  ${ParagraphAdSlotFragment}
`;
