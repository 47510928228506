import { TvvCoreAppConfig } from '../../pages/_app';
import { Dict } from '@chakra-ui/utils';

/**
 * https://themera.vercel.app/
 * https://smart-swatch.netlify.app/
 */
export const theme: Dict = {
  colors: {
    textColor: '#e21220',
    brand: {
      50: '#FDE7E9',
      100: '#FABDC1',
      200: '#F69299',
      300: '#F36871',
      400: '#F03D49',
      500: '#EC1321',
      600: '#BD0F1B',
      700: '#8E0B14',
      800: '#5E080D',
      900: '#2F0407',
    },
  },
  custom: {
    header: {
      bg: '#e21220',
      color: '#fff',
      menuActiveBg: 'brand.600',
    },
  },
};

const config: TvvCoreAppConfig = {
  gaId: 'G-95MBLX8QK3',
  usesNewLayout: false,
  fullUrl: 'https://www.bikeratlas.de',
  siteName: 'bikeratlas.de',
  frontendId: 'bikeratlas',
  frontendName: 'Bikeratlas',
  menuMain: 'bikeratlas-main',
  menuFooter: 'bikeratlas-footer',
  embedEnabled: false,
  matomoId: '3',
  globalStyleVars: {
    progressColor: '#fff',
  },
  styleConfig: {
    googleFonts: [
      'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap',
    ],
  },
  renderConfig: {
    activity: {
      renderSidebarAdTeaser: false,
      nearHotels: false,
      nearActivities: false,
      nearPois: true,
      hotelActivities: true,
      hotelActivitiesOther: false,
    },
    hotel: {
      nearActivities: false,
      nearPois: true,
      renderSidebarAdTeaser: false,
    },
    poi: {
      nearHotels: true,
      nearActivities: true,
      nearPois: true,
      renderSidebarAdTeaser: false,
    },
    freizeithotelsTeaser: true,
  },
  titles: {
    hotel: {
      activities: 'Motorrad-Touren',
    },
  },
};

export default config;
