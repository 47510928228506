import { TvvCoreAppConfig } from '../../pages/_app';
import { Dict } from '@chakra-ui/utils';
import { NewComponentTheme } from '../theme';

/**
 * https://themera.vercel.app/
 * https://smart-swatch.netlify.app/
 */
export const theme: Dict = {
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Rubik, serif',
  },
  shadows: {
    brand: '#e6656a 0 0 0 2px',
  },
  colors: {
    headerColor: '#2B2B2B',
    footerColor: '#2C2C2C',
    textColor: '#c42126',
    textColorBase: '#555',
    starColor: '#c42126',
    brand: {
      50: '#ffe5e7',
      100: '#f9bcbd',
      200: '#ef9194',
      300: '#e6656a',
      400: '#de3b40',
      500: '#c42126',
      600: '#9a181d',
      700: '#6e0f14',
      800: '#44070a',
      900: '#1e0000',
    },
    searchbar: {
      borderColor: '#c42126',
      buttonBg: '#c42126',
      iconButtonTextColor: '#fff',
    },
  },
  components: {
    ...NewComponentTheme,
    Input: {
      parts: ['field'],
      variants: {
        outline: {
          field: {
            borderWidth: '2px',
            borderColor: 'brand.500',
            boxShadow: 'brand',
            paddingX: 8,
            paddingY: 1,
            lineHeight: 'none',
            borderRadius: '3xl',
            bg: '#fff',
          },
        },
        outlineDefault: {
          field: {
            borderWidth: '1px',
            borderColor: 'gray.300',
            paddingX: 4,
            borderRadius: 'md',
            bg: '#fff',
            _focus: {
              boxShadow: 'rgb(49, 130, 206) 0px 0px 0px 1px',
              borderColor: 'rgb(49, 130, 206)',
            },
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '3xl',
      },
      variants: {
        bikerbetten: {
          bg: 'brand.500',
          borderWidth: '2px',
          borderColor: 'brand.300',
          paddingX: {
            base: 4,
            md: 8,
          },
          paddingY: 1,
          fontWeight: 'bold',
          lineHeight: 'none',
          cursor: 'pointer',
          color: '#fff',
          _hover: {
            bg: 'brand.700',
            color: '#fff',
          },
          _active: {
            bg: 'brand.700',
            color: '#fff',
          },
        },
      },
      defaultProps: {
        variant: 'bikerbetten',
      },
    },
  },
};

const config: TvvCoreAppConfig = {
  gaId: 'G-GQB41K4XC8',
  cookieScriptID: '59a413e8e4338e4c8d05a160cb324d31',
  adsense: {
    id: '8990558636310587',
  },
  newLayout: {
    cta: {
      activityTeaser: 'Check it now',
      hotelTeaser: 'Check it now',
      newsTeaser: 'Read more',
    },
  },
  queryVariables: {
    hotel: {
      activitiesInRegion: {
        conditions: [
          {
            field: 'category.entity.key',
            operator: '=',
            value: 'motorcycle',
          },
        ],
      },
    },
  },
  filters: {
    groupSearch: true,
  },
  usesNewLayout: true,
  fullUrl: 'https://www.bikerbetten.de',
  siteName: 'bikerbetten.de',
  frontendId: 'bikerbetten',
  frontendName: 'Bikerbetten',
  menuMain: 'bikerbetten-main',
  menuFooter: 'bikerbetten-footer',
  menuFooterMain: 'bikerbetten-footer-main',
  embedEnabled: false,
  matomoId: '4',
  globalStyleVars: {
    progressColor: '#de3b40',
  },
  renderConfig: {
    activity: {
      renderSidebarAdTeaser: false,
      nearHotels: true,
      nearActivities: false,
      nearPois: true,
      hotelActivities: true,
      hotelActivitiesOther: false,
    },
    hotel: {
      nearActivities: false,
      nearPois: true,
      renderSidebarAdTeaser: false,
    },
    poi: {
      nearHotels: true,
      nearActivities: true,
      nearPois: true,
      renderSidebarAdTeaser: false,
    },
    freizeithotelsTeaser: true,
  },
  titles: {
    hotel: {
      activities: 'Biker-Touren',
      furterActivities: 'Weitere Biker-Touren des Hotels',
    },
    activity: {
      hotel: 'Empfohlenes Hotel',
    },
  },
};

export default config;
