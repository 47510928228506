import { gql } from '@apollo/client';
import { PoiTableFragment } from '../../Node/Poi/fragment';

export const PassTableDataFragment = gql`
  fragment PassTableData on PoiConnection {
    total
    items {
      ...PoiTable
    }
    countries {
      id
      name
    }
    regions {
      id
      name
      country {
        id
      }
    }
  }

  ${PoiTableFragment}
`;

export const ParagraphPassTableFragment = gql`
  fragment ParagraphPassTable on ParagraphPassTable {
    id
    data(parameters: $passTableParameters) {
      ...PassTableData
    }
    regions {
      id
    }
    countries {
      id
    }
  }

  ${PassTableDataFragment}
`;
