import { gql } from '@apollo/client';
import { ParagraphColumnsFragment } from '../../Paragraph/Columns/fragment';
import { ParagraphImageFragment } from '../../Paragraph/Image/fragment';
import { ParagraphImageAndTextFragment } from '../../Paragraph/ImageAndText/fragment';
import { ParagraphLinksFragment } from '../../Paragraph/Links/fragment';
import { ParagraphTextFragment } from '../../Paragraph/Text/fragment';

export const PersonTeaserFragment = gql`
  fragment PersonTeaserFragment on Person {
    id
    title
    url
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
    }
  }
`;

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    id
    title
    homepageUrl
    job
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
    }
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    personContent: content {
      ... on ParagraphInterface {
        id
      }
      __typename

      ...ParagraphColumnsFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphImageFragment
      ...ParagraphLinksFragment
      ...ParagraphTextFragment
    }
  }

  ${ParagraphTextFragment}
  ${ParagraphColumnsFragment}
  ${ParagraphImageFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphLinksFragment}
`;
