import { createIcon } from '@chakra-ui/icons';

export const FilterIcon2 = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M496 72H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16C7.2 72 0 79.2 0 88v16c0 8.8 7.2 16 16 16h208v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h208c8.8 0 16-7.2 16-16V88c0-8.8-7.2-16-16-16zm0 320H160v-24c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h80v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h336c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm0-160h-80v-24c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h336v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h80c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"
    ></path>
  ),
  viewBox: '0 0 512 512',
});

export const SortIcon = createIcon({
  path: (
    <g>
      <path
        d="M8.854 14.646c-0.195-0.195-0.512-0.195-0.707 0l-3.146 3.146v-17.293c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v17.293l-3.146-3.146c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l4 4c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l4-4c0.195-0.195 0.195-0.512 0-0.707z"
        fill="currentColor"
      ></path>
      <path
        d="M13.5 5h-2c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5h2c0.276 0 0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5zM12 4h1v-1h-1v1z"
        fill="#000000"
      ></path>
      <path
        d="M15.5 9h-4c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5h4c0.276 0 0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5zM12 8h3v-1h-3v1z"
        fill="#000000"
      ></path>
      <path
        d="M17.5 13h-6c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5zM12 12h5v-1h-5v1z"
        fill="#000000"
      ></path>
      <path
        d="M19.5 17h-8c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5h8c0.276 0 0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5zM12 16h7v-1h-7v1z"
        fill="#000000"
      ></path>
    </g>
  ),
  viewBox: '0 0 20 20',
});

export const NotFoundIcon = createIcon({
  path: (
    <g>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M23.5,18.5   c0,1.105-0.895,2-2,2h-19c-1.104,0-2-0.895-2-2v-14c0-1.103,0.896-2,2-2h19c1.105,0,2,0.897,2,2V18.5z"
      ></path>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="0.5"
        y1="7.5"
        x2="23.5"
        y2="7.5"
      ></line>
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="4"
        cy="5"
        r="0.5"
      ></circle>
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="7"
        cy="5"
        r="0.5"
      ></circle>
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="10"
        cy="5"
        r="0.5"
      ></circle>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M12.916,14.053   c0,1.381-0.19,2.5-1.276,2.5c-1.087,0-1.276-1.119-1.276-2.5c0-1.381,0.189-2.5,1.276-2.5   C12.726,11.553,12.916,12.672,12.916,14.053z"
      ></path>
      <polyline
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="8,16.5    8,11.606 5.84,14.719 8.393,14.719  "
      ></polyline>
      <polyline
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="   17.106,16.5 17.106,11.606 14.947,14.719 17.5,14.719  "
      ></polyline>
    </g>
  ),
  viewBox: '0 0 24 24',
});

export const DoubleChevronLeftIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.7c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.3-9.4-9.3-24.6 0-34zm192 34l194.3 194.3c9.4 9.4 24.6 9.4 33.9 0l22.7-22.7c9.4-9.4 9.4-24.5 0-33.9L323.5 256l154-154.7c9.3-9.4 9.3-24.5 0-33.9l-22.7-22.7c-9.4-9.4-24.6-9.4-33.9 0L226.5 239c-9.3 9.4-9.3 24.6 0 34z"
    ></path>
  ),
  viewBox: '0 0 512 512',
});

export const DoubleChevronRightIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M477.5 273L283.1 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.5 0-33.9l154-154.7-154-154.7c-9.3-9.4-9.3-24.5 0-33.9l22.7-22.7c9.4-9.4 24.6-9.4 33.9 0L477.5 239c9.3 9.4 9.3 24.6 0 34zm-192-34L91.1 44.7c-9.4-9.4-24.6-9.4-33.9 0L34.5 67.4c-9.4 9.4-9.4 24.5 0 33.9l154 154.7-154 154.7c-9.3 9.4-9.3 24.5 0 33.9l22.7 22.7c9.4 9.4 24.6 9.4 33.9 0L285.5 273c9.3-9.4 9.3-24.6 0-34z"
    ></path>
  ),
  viewBox: '0 0 512 512',
});

export const ChevronLeftIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
    ></path>
  ),
  viewBox: '0 0 320 512',
});

export const EllipsisIcon = createIcon({
  path: (
    <g fill="currentColor">
      <circle cx="256" cy="256" r="48" />
      <circle cx="416" cy="256" r="48" />
      <circle cx="96" cy="256" r="48" />
    </g>
  ),
  viewBox: '0 0 512 512',
});

export const ChevronRightIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
    ></path>
  ),
  viewBox: '0 0 320 512',
});

export const BedIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z"
    ></path>
  ),
  viewBox: '0 0 640 512',
});

export const PoiPassIcon = createIcon({
  path: (
    <path
      d="M262.9,95.1 C218.2,99 174.5,100 130.7,108.8 C120,110.9 120.3,116.5 132.8,117 C182,118.8 231.3,115.1 280.2,120.9 L266.4,100.3 L279.4,93.6 L262.9,95.1 L262.9,95.1 Z M0.2,223.2 L25,186 C12.7,178.4 12.5,170.1 35.1,164.2 C27.2,170.1 25.1,173.8 30.7,177.5 C44.3,185 91.7,187.6 120.2,187.6 C150.3,187.6 176,187.6 203.8,188.5 C247.6,189.9 243.2,195.7 218.2,200.5 C165.1,210.6 14.8,222.5 3,223.2 L0.2,223.2 L0.2,223.2 Z M41,161.9 L62.8,129.1 C54.9,124.8 58.3,119.4 69.4,113.3 C64.5,118.7 67.2,121.1 72.6,123.3 C87.3,129.4 129.4,129.4 167.1,128.9 C196.3,128.5 213.6,128 232.6,128.2 C251.4,128.3 247.6,133.2 234.1,134.8 C169.4,142.6 102.2,142.5 41,161.9 L41,161.9 Z M74.8,111.2 L125.8,34.6 L151.8,73.6 L200.3,0.7 L257.3,86.4 C200.1,90.6 133.4,91.3 74.8,111.2 L74.8,111.2 Z M289.6,135 C297.5,129.3 294.5,124.9 286.5,121.1 C308.6,127.3 311.2,134.2 294.2,141.8 L322.6,184.5 C298.6,166.5 170.4,173.7 118.3,171.9 C85.9,170.7 102,163 115.9,160.8 C177.1,151.4 272.6,148.5 289.6,135 L289.6,135 Z M326.7,190.8 L331.1,197.5 L348.2,223.2 L251.2,223.2 L249.3,223.2 L222.4,223.2 L210.4,223.2 C245,217.9 328.3,202.5 326.7,190.8 L326.7,190.8 Z"
      id="Shape"
      fill="currentColor"
    ></path>
  ),
  viewBox: '0 0 349 224',
});

export const MerchantIcon = createIcon({
  path: (
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      fill="none"
    >
      <path d="M21.5.5h-17c-1.104 0-2 .896-2 2s.896 2 2 2h17v19h-17c-1.104 0-2-.896-2-2v-19M4.5 2.5h16M1.5 7.5h2M1.5 10.5h2M1.5 13.5h2M1.5 16.5h2M1.5 19.5h2"></path>
      <path d="M16.343 11.343c0 2.122-3.843 7.942-3.843 7.942s-3.843-5.82-3.843-7.942 1.721-3.843 3.843-3.843 3.843 1.721 3.843 3.843z"></path>
      <circle cx="12.5" cy="11.343" r="1.537"></circle>
    </g>
  ),
  viewBox: '0 0 24 24',
});

export const MountainBikeIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M9.872.89a2.996 2.996 0 1 1 2.672 5.364A2.996 2.996 0 0 1 9.872.89zm2.605 6.897c-1.552-1.018-3.997-.046-4.094 1.85l-.472 8.206c-.067 1.478 1.118 2.641 2.562 2.753l7.231.564.444 7.81a1.864 1.864 0 1 0 3.667-.669l-.686-9.154a1.865 1.865 0 0 0-1.702-1.592l-6.435-.502.327-5.129c-.012-.252.278-.399.492-.25l2.013 1.142c.427.244.93.23 1.33.01l5.715-2.862a1.36 1.36 0 1 0-1.213-2.433l-5.117 2.562-4.062-2.306zm8.202 7.185l-2.723 1.404-3.535-.29 6.654-3.54-.674-.518 1.974-.984 10.137 7.83a.993.993 0 0 1-1.215 1.57l-4.452-3.44a5.7 5.7 0 0 0-.053 5.204 5.714 5.714 0 1 0 2.248-7.492l-2.353-1.767c4.214-3.273 10.417-1.905 12.822 2.918A8.498 8.498 0 1 1 24.3 23.45a8.49 8.49 0 0 1 .32-8.167l-1.51-1.163-1.368 3.692a2.851 2.851 0 0 0-1.711-1.095l.647-1.745zM8.14 22.528l-.361 2.905c-3.475.928-5.264 4.819-3.643 8.071a5.71 5.71 0 0 0 7.661 2.563 5.71 5.71 0 0 0 3.152-4.719l-5.596.592c-.661.07-1.168-.492-1.085-1.137l1.216-9.5 1.977.177-.158 1.23a8.489 8.489 0 0 1 5.988 5.495l-2.88.303a5.693 5.693 0 0 0-3.464-3.008l-.556 4.337 7.148-.756a8.498 8.498 0 0 1-15.895 5.665C-.977 29.49 2.416 23.28 8.14 22.529" />
      <path d="M49.63 40.73V23.942c-3.033 1.552-4.447.918-5.125 1.703-1.157 1.34-3.88 2.01-4.813 2.781-.935.782-7.385 1.311-8.712 1.311-.956 0-4.252 2.759-5.187 4.256-1.229 1.964-6.905 1.327-7.586 3.007-.492 1.212-4.31 3.732-4.31 3.732H49.63" />
    </g>
  ),
  viewBox: '0 0 50 41',
});

export const SwimIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M10.697 12.437c1.696-.88 3.291-2.04 4.86-2.912 2.232-1.24 5.425-2.252 8.62-2.186a2.897 2.897 0 0 0-4.296-2.882L11.99 8.901a2.9 2.9 0 0 0-1.324 3.453z" />
      <path d="M17.39 2.783a1.207 1.207 0 1 1 1.623-1.79l3.579 3.246a1.209 1.209 0 0 1-1.624 1.789l-3.577-3.245m11.273 2.769a2.373 2.373 0 1 1-4.383-1.822 2.373 2.373 0 0 1 4.383 1.822" />
      <path d="M13.423 5.992c-.55.307-1.264.072-1.596-.523-.332-.594-.155-1.325.393-1.632L17.632.816c.55-.307 1.264-.073 1.596.521.332.596.156 1.327-.393 1.632l-5.412 3.023m-13 4.956s4.48 8.113 12.285 5.433c4.805-1.65 12.773-9.209 19.204-4.644C27.64 6.011 19.807 7.495 15.588 9.84c-4.283 2.38-8.767 7.019-15.165 1.108" />
    </g>
  ),
  viewBox: '0 0 32 17',
});

export const SkiIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M13.623 12.79a3.46 3.46 0 1 1-2.494-6.455L21.215 2.44a3.46 3.46 0 0 1 2.492 6.455l-10.084 3.894" />
      <path d="M21.408 14.49c.66.475.572 1.725-.197 2.79-.768 1.065-1.927 1.543-2.586 1.067l-7.885-5.69c-.66-.476-.57-1.725.197-2.789.77-1.065 1.927-1.543 2.586-1.068l7.885 5.69" />
      <path d="M17.11 26.97c-.405.871-1.56 1.197-2.578.726-1.02-.47-1.52-1.56-1.116-2.433l4.82-10.432c.404-.874 1.557-1.198 2.577-.727 1.02.472 1.519 1.561 1.116 2.433L17.11 26.97m13.073-14.915a1.44 1.44 0 1 1-.704 2.794l-5.591-1.409a1.442 1.442 0 0 1 .704-2.796l5.59 1.41" />
      <path d="M25.052 6.128a1.441 1.441 0 0 0-2.865.313l.626 5.732a1.44 1.44 0 1 0 2.866-.314l-.627-5.731m6.912-1.043a3.173 3.173 0 0 1-5.25-3.558 3.171 3.171 0 0 1 5.25 3.558M28.49 29.528H6.123c-.993 0-1.4-.477-1.4-1.067 0-.589.407-1.068 1.4-1.068h22.365c.994 0 1.4.479 1.4 1.068 0 .59-.406 1.067-1.4 1.067z" />
      <path d="M32.068 26.41c.312.5.23 1.114-.18 1.37l-2.495 1.553c-.41.256-.997.059-1.309-.441-.312-.501-.232-1.115.18-1.37l2.495-1.554c.41-.256.997-.059 1.309.443m-6.303-14.207c-.066.268-.288.443-.494.392L.729 6.476c-.206-.05-.318-.31-.252-.577.067-.267.287-.443.495-.391l24.54 6.119c.208.052.32.31.253.577" />
      <path d="M3.112 8.135c-.09.356-.388.589-.667.52-.28-.071-.433-.416-.345-.772l.741-2.972c.09-.358.388-.59.667-.52.28.069.435.416.345.772l-.741 2.972" />
    </g>
  ),
  viewBox: '0 0 33 30',
});

export const RidingIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M11.036 12.399a2.28 2.28 0 1 1-2.999-3.435l5.367-4.684a2.28 2.28 0 1 1 2.999 3.435l-5.367 4.684" />
      <path d="M15.27 13.07c.46.274.475 1.1.031 1.842-.442.744-1.176 1.124-1.636.85L8.163 12.48c-.46-.275-.474-1.1-.031-1.843.444-.744 1.176-1.124 1.636-.849l5.503 3.281" />
      <path d="M13.165 21.508c-.214.596-.953.876-1.649.625-.696-.25-1.088-.937-.872-1.533l2.565-7.124c.214-.596.952-.876 1.65-.625.696.252 1.086.937.872 1.533l-2.566 7.124M17.01 5.16a1.234 1.234 0 1 0-1.873 1.608l3.216 3.748a1.234 1.234 0 1 0 1.873-1.608L17.012 5.16m3.644-1.665a2.091 2.091 0 0 1-4.053-1.02 2.091 2.091 0 0 1 4.053 1.02" />
      <path d="M22.235 17.09c1.518-.389 2.185-.943 2.714-1.695.527-.758.883-1.772 1.616-2.963.123-.221.38-.39.634-.38.366.012.622.21.905.436.277.23.567.523.87.815.587.58 1.25 1.14 1.763 1.232l.03-.008.136-.062c.11-.06.25-.152.394-.265.288-.224.595-.53.772-.796.122-.175.174-.335.168-.381v-.011c-.152-1.316-.969-3.241-1.368-4.1a15.175 15.175 0 0 0-.217-.457l-.057-.115.029-.125.007-.028.016-.09.022-.26-.018-.158c-.182-.23-.515-.37-.814-.599a.822.822 0 0 1-.266-.352l-.046-.255a.89.89 0 0 1 .231-.569c.127-.15.279-.27.444-.38.316-.21.684-.384.979-.524a2.5 2.5 0 0 0-.746-.104c-.585-.001-1.288.161-2.169.37-.188.042-.399.045-.65.046-.314 0-.669-.012-.94-.012a2.403 2.403 0 0 0-.343.015c-1.291.246-2.215.985-3.15 1.952-.932.961-1.848 2.14-3.105 3.15-2.619 2.107-4.916 3.654-8.295 3.651l-.238-.003c-1.572-.033-3.627-.36-5.515-.358-1.11 0-2.155.113-2.99.441-.835.332-1.457.847-1.818 1.708l-.656-.272c.435-1.055 1.25-1.724 2.216-2.097.968-.379 2.092-.49 3.248-.491 1.963.001 4.036.33 5.53.36l.223.003c3.142-.004 5.235-1.4 7.85-3.496 1.178-.944 2.072-2.088 3.04-3.091.964-.999 2.026-1.87 3.525-2.155a2.69 2.69 0 0 1 .479-.028c.29 0 .644.012.94.012.228.002.436-.012.486-.026.879-.207 1.62-.388 2.332-.39a3.018 3.018 0 0 1 1.642.45c.07.036.196.169.192.337a.415.415 0 0 1-.102.267l-.113.102-.227.134c-.165.085-.37.178-.584.284-.425.197-.882.481-.976.649l-.029.065.003.014h.002l.046.042.23.154c.199.124.471.282.694.558.16.215.162.419.168.596 0 .163-.02.311-.038.411.26.54 1.388 2.925 1.602 4.681l.005.094c-.016.437-.27.782-.561 1.126-.299.338-.66.643-.987.848-.227.134-.405.239-.664.25l-.095-.007c-.636-.119-1.18-.535-1.681-.976-.497-.444-.952-.933-1.31-1.23-.217-.19-.409-.274-.446-.274l-.033.044c-.694 1.12-1.036 2.117-1.638 2.993-.6.88-1.494 1.579-3.125 1.983l-.173-.69z" />
    </g>
  ),
  viewBox: '0 0 33 23',
});

export const KanuIcon = createIcon({
  path: (
    <g fill="currentColor" transform="matrix(1 0 0 -1 .153 25.616)">
      <path d="M16.247 7.621a2.935 2.935 0 1 0-5.554 1.9l2.968 8.675a2.933 2.933 0 0 0 5.552-1.899l-2.966-8.676" />
      <path d="M21.93 14.804a1.222 1.222 0 1 0-1.877-1.567l-3.132 3.756a1.22 1.22 0 0 0 .156 1.72 1.221 1.221 0 0 0 1.722-.155l3.132-3.754m-.81 8.9a2.566 2.566 0 1 0-4.937-1.402 2.566 2.566 0 0 0 4.937 1.402" />
      <path d="M25.945 15.56a1.221 1.221 0 1 0 .159-2.439l-4.879-.32a1.22 1.22 0 1 0-.16 2.439l4.88.32" />
      <path d="M29.465 10.037h-24.9c-1.933 0-3.138 1.475-3.6 3.468-.085-.37-.958-2.601-.965-3.468-.02-2.555 2.272-4.625 4.565-4.625h24.9c2.291 0 4.586 2.07 4.565 4.625-.007.867-.88 3.097-.966 3.468-.461-1.993-1.667-3.468-3.599-3.468" />
      <polyline points="13.039 4.54 12.675 5.081 26.4 15.096 26.763 14.554 13.039 4.54" />
      <polyline points="13.273 4.244 12.909 4.784 26.635 14.798 26.999 14.258 13.273 4.244" />
      <path d="M16.403 4.736c.182.836-.938 2.552-1.808 2.581-1.466.05-5.276-1.636-6.068-2.867-.654-1.016 1.941-4.697 3.106-4.437 1.404.313 4.463 3.313 4.77 4.723" />
      <path d="M16.156 5.092c.184.836-.937 2.552-1.807 2.581-1.466.05-5.277-1.635-6.069-2.867C7.627 3.79 10.223.11 11.387.37c1.404.312 4.464 3.312 4.77 4.722" />
      <path d="M15.923 4.824c.166.76-.852 2.32-1.643 2.348-1.333.045-4.797-1.488-5.517-2.607-.595-.924 1.765-4.27 2.824-4.033 1.276.284 4.057 3.01 4.336 4.292" />
      <path d="M29.465 10.601h-24.9c-1.933 0-3.138 1.475-3.6 3.468C.88 13.7.007 11.47 0 10.601c-.02-2.553 2.272-4.624 4.565-4.624h24.9c2.291 0 4.586 2.07 4.565 4.624-.007.868-.88 3.099-.966 3.468-.461-1.993-1.667-3.468-3.599-3.468" />
    </g>
  ),
  viewBox: '0 0 35 26',
});

export const GolfIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M.344 14.264c-.541.867-.216 2.047.728 2.636l.853.533c.944.59 2.147.364 2.688-.502l4.899-7.844c.541-.867.216-2.047-.728-2.636l-.853-.534c-.943-.588-2.147-.364-2.688.503L.344 14.264" />
      <path d="M4.081 15.053c-.078-.893-1.04-1.538-2.148-1.441-1.106.096-1.941.899-1.864 1.792l.771 8.8c.077.893 1.039 1.539 2.147 1.441 1.108-.096 1.942-.898 1.864-1.792l-.77-8.8" />
      <path d="M1.595 32.76c.077.893 1.038 1.539 2.146 1.441 1.108-.097 1.943-.898 1.864-1.793l-.769-8.8c-.077-.892-1.04-1.537-2.147-1.441-1.109.097-1.942.9-1.864 1.793l.77 8.8m6.59-31.023a2.667 2.667 0 0 1 3.764-.2 2.665 2.665 0 1 1-3.764.2M7.028 9.105c-.201-.873.293-1.733 1.105-1.921.814-.188 1.636.368 1.838 1.241l1.989 8.607c.201.873-.293 1.735-1.107 1.921-.812.188-1.634-.368-1.836-1.241L7.028 9.105m6.265 23.163c-.049.75.578 1.979 2.159 2.113 1.843.159 2.695-.721 2.816-1.79.084-.746-.997-1.147-2.368-1.271-2.347-.213-2.559.199-2.607.948" />
      <path d="M11.067 22.432c.044.23.33.367.641.307.31-.059.527-.292.483-.522l-.77-4.042c-.044-.228-1.168-.015-1.124.214l.77 4.043" />
      <path d="M13.359 32.804l-2.092-10.47c-.04-.209.137-.417.28-.445.141-.026.382.103.422.311l1.904 10.505c.04.208-.044.4-.185.427-.143.027-.29-.12-.33-.328" />
    </g>
  ),
  viewBox: '0 0 19 35',
});

export const MotorcycleIcon = createIcon({
  path: (
    <g fill="currentColor">
      <polygon points="3.047 14.672 2.828 12.75 3.775 12.105 7.03 12.75 9.001 13.18 10.739 10.754 13.914 13.113 13.914 13.38 16.47 13.18 16.47 12.403 19.118 12.403 19.796 12.713 22.872 11.477 18.138 7.844 14.448 12.105 11.289 9.701 14.448 5.549 15.915 4.772 17.067 4.772 18.405 5.549 20.26 6.852 23.806 9.701 24.981 6.852 25.567 6.476 26.397 6.852 27.667 8.604 29.354 11.076 29.832 12.713 29.562 14.043 29.067 15.103 25.896 15.436 24.188 16.354 22.872 18.376 22.095 20.163 21.578 21.784 19.447 22.747 15.915 22.747 11.934 22.747 12.167 20.163 11.289 17.891 9.857 16.354 8.087 15.436 5.509 14.672" />
      <path d="M5.5 15.38a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zm0 2a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm23-2a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zm0 2a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z" />
      <polygon points="14.484 4.568 14.484 2.758 14.484 1.635 15.347 .71 16.673 0 18.024 0 18.971 .427 19.831 .901 20.202 1.635 20.202 2.087 18.584 2.087 18.171 2.284 18.171 2.936 18.326 3.395 18.971 3.732 19.831 3.732 20.396 3.986 20.396 4.772 20.396 5.353 20.202 5.89 19.367 5.469 18.584 4.92 17.586 4.336 16.673 3.986 15.908 3.986 15.104 4.211" />
    </g>
  ),
  viewBox: '0 0 34 27',
});

export const EbikeIcon = createIcon({
  path: (
    <g fill="currentColor" transform="matrix(1 0 0 -1 -2 47)">
      <path d="M13.713 9.607c5.738 0 10.39 4.162 10.39 9.3 0 5.136-4.652 9.302-10.39 9.302-1.572 0-2.712-1.405-3.421-3.436H4.355c-1.068 0-1.931-.816-1.931-1.822 0-1.007.863-1.824 1.93-1.824h5.173a22.242 22.242 0 0 1 0-4.439H4.355c-1.068 0-1.931-.816-1.931-1.823 0-1.006.863-1.822 1.93-1.822h5.938c.71-2.034 1.85-3.436 3.421-3.436zm2.526 5.968c-.087-.123-.199-.163-.286-.102-.086.063-.13.214-.113.386l.308 2.936-3.57-2.196c-.105-.065-.207-.024-.261.097l-.024.067c-.042.17 0 .397.104.542l3.94 5.547c.088.123.2.163.287.101.087-.061.13-.213.113-.385l-.311-2.937 3.573 2.197c.105.065.206.024.26-.097l.024-.067a.705.705 0 0 0-.103-.543z" />
      <path d="M49.277 25.676c3.607-3.605 3.607-9.524 0-13.13-3.605-3.606-9.524-3.606-13.13 0-3.606 3.606-3.606 9.525 0 13.13.345.345.718.664 1.113.95l-.473 1.093-7.039-9.254.633 3.472 5.676 7.463-.016.036-4.778-.216a2.72 2.72 0 0 1-1.028 1.233l-.464.303 7.306.321 2.81-6.482c.357-.827.81-1.848 1.374-2.774.524-.857 1.138-1.62 1.84-2.01a.801.801 0 0 0-.777-1.399c-.984.545-1.78 1.512-2.43 2.575-.609.997-1.093 2.089-1.477 2.973l-.398.921a7.907 7.907 0 0 1-.566-.51c-2.889-2.89-2.889-7.631 0-10.519 2.888-2.888 7.63-2.888 10.519 0 2.888 2.888 2.888 7.63 0 10.519a7.455 7.455 0 0 1-7.703 1.766l-.752 1.695a9.312 9.312 0 0 0 9.76-2.156zM36.025 46.577a3.452 3.452 0 1 0 0-6.905 3.453 3.453 0 1 0 0 6.905zM19.604 37.29c-1.567-1.078-1.99-4.249-.365-5.309l7.304-4.76-1.631-8.95a2 2 0 0 1 3.937-.706l1.852 10.164a2 2 0 0 1-.861 2.12l-5.728 3.733c1.588 1.071 3.69 2.443 5.535 3.467.388.243.597.041.665-.227l.963-3.856a1.603 1.603 0 0 1 1.654-1.217l6.688.264a1.6 1.6 0 1 1 0 3.2l-5.536-.26-1.421 5.703a2.81 2.81 0 0 1-3.999 2.21c-2.936-1.401-7.041-4.189-9.057-5.576" />
      <polyline points="18.28 21.936 17.123 23.169 27.757 31.009 28.916 29.775 18.28 21.936" />
    </g>
  ),
  viewBox: '0 0 50 38',
});

export const BikeIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M31.939 15.656c2.62 2.619 2.62 6.917 0 9.537-2.619 2.619-6.918 2.619-9.538 0-2.618-2.62-2.618-6.918 0-9.537a6.77 6.77 0 0 1 .81-.69l-.344-.794-5.112 6.721.458-2.522 4.124-5.42-.012-.026-3.472.156a1.977 1.977 0 0 0-.746-.896l-.336-.218 5.306-.234 2.04 4.71c.26.598.59 1.341 1 2.013.38.623.826 1.177 1.336 1.46a.582.582 0 0 1-.565 1.016c-.715-.396-1.293-1.097-1.764-1.87-.443-.723-.795-1.518-1.073-2.16l-.29-.669a5.42 5.42 0 0 0-.41.371c-2.099 2.099-2.099 5.543 0 7.64 2.097 2.099 5.541 2.099 7.64 0 2.097-2.097 2.097-5.541 0-7.64a5.417 5.417 0 0 0-5.595-1.283l-.547-1.23a6.76 6.76 0 0 1 7.09 1.565zm-17.782 2.915l-1.977-4.586-.881 1.263a6.729 6.729 0 0 1 2.421 5.079 7.109 7.109 0 0 1-.36 2.278 6.747 6.747 0 0 1-6.383 4.563 6.743 6.743 0 0 1-4.769-11.512 6.76 6.76 0 0 1 7.572-1.367l-.785 1.123a5.417 5.417 0 0 0-5.838 1.192 5.402 5.402 0 1 0 9.03 5.255l-5.302-.86a.58.58 0 0 1-.482-.666l.113-.262 5.123-7.34-.023-.052 1.765 1.15 1.154 2.672-.378 2.07zm-1.785 2.14a5.393 5.393 0 0 0-1.845-4.359l-2.544 3.647 4.389.712zM22.313.475a2.508 2.508 0 1 1 0 5.015 2.508 2.508 0 0 1 0-5.015zM10.385 7.22c-1.137.784-1.445 3.087-.264 3.856l5.306 3.458-1.186 6.5a1.453 1.453 0 0 0 2.86.513l1.346-7.381c.13-.578-.102-1.2-.627-1.542l-4.159-2.71c1.152-.779 2.679-1.775 4.02-2.519.282-.176.434-.03.483.165l.699 2.8c.136.556.65.919 1.201.884l4.859-.19a1.162 1.162 0 0 0 0-2.324l-4.022.188-1.032-4.142a2.042 2.042 0 0 0-2.905-1.605c-2.132 1.016-5.115 3.041-6.579 4.05" />
    </g>
  ),
  viewBox: '0 0 34 28',
});

export const LocationIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M11.5,0.006c-5.416,0-9.5,4.298-9.5,10c0,7.173,8.831,13.634,9.207,13.905c0.087,0.064,0.191,0.095,0.293,0.095  s0.205-0.031,0.293-0.095C12.168,23.64,21,17.179,21,10.006C21,4.305,16.916,0.006,11.5,0.006z M11.5,15.006  c-3.033,0-5.5-2.468-5.5-5.5c0-3.033,2.467-5.5,5.5-5.5c3.032,0,5.5,2.467,5.5,5.5C17,12.539,14.532,15.006,11.5,15.006z"
    ></path>
  ),
});

export const PoiIcon = createIcon({
  path: (
    <g stroke="currentColor" fill="none">
      <path d="M0 23.5h23M0 16.5h23M1.5 16.5v7M21.5 16.5v7M3 9.5h17M5.5 9.5v7M9.5 12v2.5M13.5 12v2.5M9.5 18.5v2.5M5.5 18.5v2.5M13.5 18.5v2.5M17.5 18.5v2.5M17.5 9.5v7"></path>
      <path
        strokeLinecap="round"
        d="M5.5 9.5c0-3.314 2.687-6 6-6s6 2.686 6 6h-12zM11.5 3.5v-2.959"
      ></path>
    </g>
  ),
});

export const HikingIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M13.25 14.423c.37.485 1.198.477 1.849-.018.652-.496.88-1.292.51-1.778l-3.648-4.791c-.369-.485-1.197-.477-1.849.019-.65.496-.879 1.29-.51 1.777l3.649 4.79" />
      <path d="M18.215 14.896c.429.013.966-.627.99-1.444.026-.819-.473-1.505-.901-1.519l-4.052-.113c-.43-.012-.995.63-1.02 1.447-.024.818.503 1.504.93 1.516l4.053.113m-10.822 5.62c.3-1.147-.304-2.299-1.352-2.57-1.046-.275-2.14.433-2.441 1.58L.635 30.827c-.302 1.147.304 2.299 1.352 2.572 1.046.273 2.14-.435 2.441-1.581l2.965-11.303" />
      <path d="M6.821 20.516c-.301-1.147.304-2.299 1.352-2.57 1.047-.275 2.14.433 2.442 1.58l2.965 11.302c.301 1.147-.304 2.299-1.352 2.572-1.047.273-2.14-.435-2.441-1.581L6.82 20.516m5.767-18.873a2.593 2.593 0 1 1-4.249 2.974 2.593 2.593 0 0 1 4.249-2.974" />
      <path d="M10.593 19.736c-.242 1.377-1.656 2.279-3.154 2.015l-1.36-.24c-1.499-.264-2.519-1.595-2.276-2.972L5.599 8.348c.242-1.376 1.656-2.279 3.156-2.015l1.358.24c1.5.264 2.52 1.596 2.278 2.972l-1.798 10.191M3.8 15.23a1.844 1.844 0 1 1-3.632-.642l1.03-5.837a1.844 1.844 0 0 1 3.633.64L3.8 15.229m14.553 17.894c0 .218-.265.394-.593.394-.327 0-.593-.176-.593-.394V11.189c0-.218.266-.396.593-.396.328 0 .593.178.593.396v21.934" />
    </g>
  ),
  viewBox: '0 0 20 34',
});

export const FilterIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M472,168H40a24,24,0,0,1,0-48H472a24,24,0,0,1,0,48Z"></path>
      <path d="M392,280H120a24,24,0,0,1,0-48H392a24,24,0,0,1,0,48Z"></path>
      <path d="M296,392H216a24,24,0,0,1,0-48h80a24,24,0,0,1,0,48Z"></path>
    </g>
  ),
  viewBox: '0 0 512 512',
});

export const MapSearchIconAlt = createIcon({
  path: (
    <g fill="currentColor">
      <g>
        <path d="M12,17.27c0-1.669,0.791-3.144,2-4.117V0.25L8,4.535v17.227l4.229-3.033C12.095,18.263,12,17.779,12,17.27z"></path>
        <path d="M15,12.513c0.7-0.34,1.476-0.548,2.306-0.548c2.05,0,3.812,1.181,4.694,2.889V5.506c0-0.162-0.078-0.313-0.21-0.407   L15,0.25V12.513z"></path>
        <path d="M7,4.535L0.79,0.099c-0.151-0.108-0.353-0.124-0.52-0.038C0.105,0.148,0,0.318,0,0.506v16c0,0.162,0.078,0.313,0.209,0.407   L7,21.763V4.535z"></path>
      </g>
      <path d="M23.854,23.11l-3.186-3.186c0.581-0.733,0.942-1.648,0.942-2.655c0-2.374-1.932-4.305-4.305-4.305  c-2.374,0-4.306,1.931-4.306,4.305c0,2.374,1.932,4.305,4.306,4.305c1.007,0,1.922-0.362,2.655-0.942l3.186,3.185  c0.098,0.098,0.226,0.147,0.354,0.147s0.256-0.05,0.354-0.147C24.049,23.622,24.049,23.306,23.854,23.11z M14,17.27  c0-1.823,1.482-3.305,3.306-3.305c1.822,0,3.305,1.482,3.305,3.305c0,1.823-1.483,3.305-3.305,3.305  C15.482,20.575,14,19.093,14,17.27z"></path>
    </g>
  ),
});

export const MapSearchIcon = createIcon({
  path: (
    <g stroke="currentColor" fill="none">
      <path d="M7.5 5.5v16M14.5 10.5v-10M11.497 18.645l-3.997 2.855-7-5v-16l7 5 7-5 7 5v7"></path>
      <circle cx="17.305" cy="17.307" r="3.805"></circle>
      <path d="M20 20l3.5 3.5"></path>
    </g>
  ),
});

export const TargetIcon = createIcon({
  path: (
    <g stroke="currentColor" fill="none">
      <circle cx="11.5" cy="11.5" r="8"></circle>
      <circle cx="11.5" cy="11.5" r="3.5"></circle>
      <path d="M11.5.5v3M11.5 19.5v3M22.5 11.5h-3M3.5 11.5h-3"></path>
    </g>
  ),
});

export const CustomStarEmpty = createIcon({
  path: (
    <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path>
  ),
  viewBox: '0 0 576 512',
});

export const CustomStarFilled = createIcon({
  path: (
    <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
  ),
  viewBox: '0 0 576 512',
});

export const UserLocationIcon = createIcon({
  path: (
    <g fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
    </g>
  ),
});

export const PersonIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
    ></path>
  ),
  viewBox: '0 0 448 512',
});

export const HotelFeatureGPSIcon = createIcon({
  path: (
    <g fill="none" fillRule="evenodd" transform="translate(.214 .422)">
      <polyline
        stroke="currentColor"
        strokeWidth="2.114"
        points="25.478 29.587 25.478 29.587 11.333 41.931"
      />
      <path
        fill="currentColor"
        d="M43.579 32.956l-.016-.002a6.066 6.066 0 0 0-3.51.668l-8.973 4.747a1.85 1.85 0 0 1-.865.215H17.501v-.169c0-.496.402-.897.897-.897h5.458a4.034 4.034 0 0 0 4.034-4.034c0-.641-.52-1.16-1.161-1.16H10.892c-1.45 0-2.831.62-3.795 1.703l-4.769 5.362v11.94h3.826c1.559 0 3.111-.222 4.609-.66l21.538-6.291a7.374 7.374 0 0 0 2.409-1.219l9.653-7.38c1.131-.864.63-2.666-.784-2.823M28.705 20.005L20.534 4.388a.292.292 0 0 0-.136-.131l-.009-.005a.286.286 0 0 0-.078-.022l-.027-.002-.015-.001a.297.297 0 0 0-.264.161l-8.171 15.617a.3.3 0 0 0 .265.438.288.288 0 0 0 .115-.023l8.055-3.337h.001l8.055 3.337a.247.247 0 0 0 .057.017c.007.002.014.001.02.002.013.002.025.004.037.004h.001a.301.301 0 0 0 .221-.097.302.302 0 0 0 .044-.341zm-8.436-4.897l-.362.152-.335.138-4.175 1.73 4.872-9.313.001.001v7.222l-.001.07z"
      />
      <path
        fill="currentColor"
        d="M37.48 0H3.059C1.37 0 0 1.43 0 3.195v22.139c0 1.765 1.37 3.196 3.059 3.196H37.48c1.69 0 3.059-1.431 3.059-3.196V3.195C40.539 1.43 39.17 0 37.48 0zM23.936 26.204h-7.333a.794.794 0 0 1 0-1.588h7.333a.794.794 0 0 1 0 1.588zm14.22-15.313h-.001v10.591c0 .44-.309.812-.676.812H3.059c-.364 0-.672-.368-.676-.804V3.195c0-.44.31-.812.676-.812H37.48c.366 0 .676.372.676.812v7.696z"
      />
    </g>
  ),
  viewBox: '0 0 46 52',
});

export const HotelFeatureLoadingIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.982 21.219h2.16v-4.871a1.016 1.016 0 0 1 2.031 0v4.871h2.857v-4.871a1.016 1.016 0 0 1 2.031 0v4.871h2.973v4.773c0 1.637-.67 3.124-1.748 4.202a5.936 5.936 0 0 1-3.669 1.724v6.553a5.067 5.067 0 0 1-1.49 3.587 5.067 5.067 0 0 1-3.587 1.49h-5.8v-2.031h5.8a3.04 3.04 0 0 0 2.151-.895 3.04 3.04 0 0 0 .895-2.151v-6.553a5.939 5.939 0 0 1-3.669-1.724 5.93 5.93 0 0 1-1.747-4.202v-4.773h.812zM8.697.585h6.515c.394 0 .752.161 1.011.42.259.259.42.617.42 1.011v1.576H7.267V2.016c0-.394.16-.752.42-1.011.259-.259.616-.42 1.01-.42zM4.092 5.029h15.725c.951 0 1.814.389 2.439 1.014a3.442 3.442 0 0 1 1.014 2.439v39.836c0 .951-.389 1.814-1.014 2.44a3.442 3.442 0 0 1-2.439 1.013H4.092c-.95 0-1.814-.388-2.439-1.013a3.446 3.446 0 0 1-1.013-2.44V8.482c0-.951.388-1.814 1.013-2.439a3.44 3.44 0 0 1 2.439-1.014zM19.817 7.06H4.092c-.39 0-.745.161-1.003.419a1.416 1.416 0 0 0-.418 1.003v39.836c0 .39.16.746.418 1.004s.613.418 1.003.418h15.725c.39 0 .745-.16 1.003-.418a1.42 1.42 0 0 0 .419-1.004V8.482c0-.39-.161-.745-.419-1.003a1.413 1.413 0 0 0-1.003-.419zM4.072 40.199h15.766v-6.821H4.072v6.821zm0 8.14h15.766v-6.822H4.072v6.822zm0-16.405h15.766v-6.821H4.072v6.821z"
    />
  ),
  viewBox: '0 0 42 52',
});

export const HotelFeatureMapIcon = createIcon({
  path: (
    <g fill="none" fillRule="evenodd" transform="translate(.876 1.303)">
      <path
        stroke="currentColor"
        strokeWidth=".744"
        d="M52.248 22.972l-1.276-2.753-.001.002-.882-1.906.003-.001-3.872-8.362h-.003l-.271-.585h.004l-2.759-5.962-.003.002-.241-.518.003-.001L41.806.415 31.649 2.073 21.303 0 10.572 2.405v.001h-.002L.237 26.124 0 26.675l.543-.138.584-.418 17.3-4.388 13.37 4.589 19.86-2.984.716-.11-.127-.251-.001-.002.003-.001z"
      />
      <path
        fill="currentColor"
        d="M10.793 4.866l4.808-1.15c1.493-.36 3.992-.037 4.749 2.331.669 2.087 1.426 4.453 5.212 4.984.644.089 1.277.164 1.9.229l-.963 1.961a52.859 52.859 0 0 1-1.313-.164c-5.283-.74-6.385-4.183-7.114-6.46-.371-1.161-1.502-.985-1.84-.904L9.76 7.243l1.033-2.377"
      />
      <polyline
        fill="currentColor"
        points="11.256 21.615 10.784 19.228 7.097 18.957 13.876 16.794 11.256 21.615"
      />
      <path
        fill="currentColor"
        d="M35.32 25.404l-.642-4.826-5.942-1.964-1.183-1.733c-.017.009-.033.018-.053.024l-4.855 1.686-.97 3.841-.616-.178 1.025-4.072 5.14-1.784-.768-1.126-6.208-1.317-5.306 2.115-8.202-1.877.23-.527 7.913 1.813 5.299-2.114 6.292 1.336 3.24-6.604 12.225-4.394.24.52-5.345 1.92 4.155 3.268 3.738.331.272.587-4.286-.38-4.537-3.569-5.971 2.146-3.181 6.483 2.152 3.151 6.096 2.016.727 5.092-.679.136"
      />
      <path
        fill="currentColor"
        d="M43.88 21.893c-.885 0-1.713-.087-2.413-.299-2.898-.873-3.514-2.233-4.111-3.548-.449-.991-.914-2.016-2.466-3.258-1.057-.847-3.379-1.07-6.067-1.328l-.324-.03.968-1.972c2.939.283 5.465.571 7.027 1.822 1.95 1.562 2.567 2.921 3.063 4.013.543 1.198.816 1.798 2.686 2.363 1.572.474 4.475-.005 6.802-.562l.886 1.919c-1.759.435-4.047.88-6.051.88"
      />
      <text
        fill="currentColor"
        fontFamily="LucidaGrande, Lucida Grande"
        fontSize="22"
        letterSpacing="-.003"
      >
        <tspan x="5.124" y="49.697">
          M
        </tspan>{' '}
        <tspan x="24.07" y="49.697" letterSpacing="-.125">
          a
        </tspan>{' '}
        <tspan x="36.095" y="49.697">
          p
        </tspan>
      </text>
    </g>
  ),
  viewBox: '0 0 54 56',
});

export const HotelFeatureParkIcon = createIcon({
  path: (
    <g fill="none" fillRule="evenodd" transform="translate(.849 3.239)">
      <path
        fill="currentColor"
        d="M42.401 46.166c-5.733 0-10.397-4.631-10.397-10.324 0-5.692 4.664-10.323 10.397-10.323 2.723 0 5.314 1.072 7.295 3.02 2 1.965 3.101 4.559 3.101 7.303 0 5.693-4.664 10.324-10.396 10.324zm0-18.361c-4.464 0-8.095 3.605-8.095 8.037s3.631 8.037 8.095 8.037c4.462 0 8.095-3.605 8.095-8.037 0-4.356-3.707-8.037-8.095-8.037z"
      />
      <path
        fill="currentColor"
        d="M43.437 35.842c0 .569-.464 1.029-1.036 1.029-.573 0-1.038-.46-1.038-1.029 0-.568.465-1.029 1.038-1.029.572 0 1.036.461 1.036 1.029"
      />
      <path
        fill="currentColor"
        d="M42.717 36.654a.876.876 0 0 1-1.135-.497l-7.33-20.237a.871.871 0 0 1 .548-1.144c.275-.088 1.513-.571 1.711-.778l.117-.122 1.418 1.425s-1.887.545-1.691 1.186l6.863 19.04a.869.869 0 0 1-.501 1.127"
      />
      <path
        fill="currentColor"
        d="M39.622 28.014l-1.641.614-.77-1.844a.833.833 0 0 1 .229-.932l.959-.84 1.223 3.002m-13.339 7.924a2.787 2.787 0 0 1-2.903 2.66 2.781 2.781 0 0 1-2.679-2.883 2.784 2.784 0 0 1 2.903-2.66 2.782 2.782 0 0 1 2.679 2.883"
      />
      <path
        fill="currentColor"
        d="M23.469 36.593a.987.987 0 0 1-.808-.631l-3.97-10.606.094-.251-2.662-7.163c-.191-.507-.127-1.096.384-1.285.511-.19.819.037 1.009.545l2.707 7.298.335.228 3.954 10.548a.978.978 0 0 1-.58 1.262.988.988 0 0 1-.463.055"
      />
      <path
        fill="currentColor"
        d="M13.322 16.415a.8.8 0 0 1-.096-.627.79.79 0 0 1 .765-.579l6.794.015a.787.787 0 0 1 .777.654.783.783 0 0 1-.517.872l-4.153 1.124a3.253 3.253 0 0 1-3.57-1.459m11.434 24.831a.495.495 0 0 1-.374-.353l-1.039-3.792a.493.493 0 0 1 .35-.605.497.497 0 0 1 .61.346l1.038 3.792a.492.492 0 0 1-.349.605.497.497 0 0 1-.236.007"
      />
      <path
        fill="currentColor"
        d="M25.86 40.379h-2.037a.677.677 0 0 0-.679.674c0 .371.305.673.679.673h2.037a.676.676 0 0 0 .678-.673.676.676 0 0 0-.678-.674"
      />
      <polygon
        fill="currentColor"
        points="10.748 36.713 23.492 36.713 23.492 34.971 10.748 34.971"
      />
      <polyline
        fill="currentColor"
        points="11.37 36.441 10.095 35.243 19.151 24.709 20.424 25.907 11.37 36.441"
      />
      <path
        fill="currentColor"
        d="M38.089 22.404c-4.364 3.834-8.895 8.796-13.526 14.426l-2.142-2.929 3.974-4.648.792-.924 1.501-1.711 2.189-2.49 1.505-1.714.522-.446 3.374-2.874 1.811 3.31m.799-8.316c0 .717-.585 1.297-1.305 1.297a1.3 1.3 0 0 1-1.306-1.297 1.3 1.3 0 0 1 1.306-1.296 1.3 1.3 0 0 1 1.305 1.296"
      />
      <path
        fill="currentColor"
        d="M35.427 20.213l1.297 3.518c.078.209.31.319.522.246l1.579-.545a.406.406 0 0 0 .25-.528 3127.39 3127.39 0 0 1-1.327-3.543.413.413 0 0 0-.529-.24l-1.548.569a.407.407 0 0 0-.244.523m-.959 3.109l-2.379 2.408-2.031 2.052-2.921 2.959-1.327-2.661a.855.855 0 0 1 .116-.931l4.974-5.774a.86.86 0 0 1 1.215-.089l.789.682 1.564 1.354M10.396 46.15C4.665 46.15 0 41.519 0 35.826c0-5.692 4.665-10.323 10.396-10.323 2.725 0 5.315 1.073 7.297 3.02 1.999 1.965 3.101 4.559 3.101 7.303 0 5.693-4.664 10.324-10.398 10.324zm0-18.361c-4.463 0-8.095 3.606-8.095 8.037 0 4.432 3.632 8.037 8.095 8.037 4.464 0 8.095-3.605 8.095-8.037 0-4.356-3.707-8.037-8.095-8.037z"
      />
      <path
        fill="currentColor"
        d="M11.433 35.826c0 .569-.464 1.03-1.037 1.03a1.033 1.033 0 0 1-1.036-1.03c0-.568.464-1.029 1.036-1.029.573 0 1.037.461 1.037 1.029"
      />
      <path
        stroke="currentColor"
        strokeWidth="5.338"
        d="M25.011 0l26.551 13.851M27.785 0L1.234 13.851"
      />
    </g>
  ),
  viewBox: '0 0 54 50',
});

export const HotelFeatureRentIcon = createIcon({
  path: (
    <g fill="currentColor" fillRule="evenodd" transform="translate(.732 .426)">
      <path d="M42.401 33.374c-5.733 0-10.397-4.631-10.397-10.323s4.664-10.323 10.397-10.323c2.723 0 5.315 1.072 7.295 3.019 2 1.966 3.102 4.56 3.102 7.304 0 5.692-4.664 10.323-10.397 10.323zm0-18.36c-4.464 0-8.094 3.605-8.094 8.037s3.63 8.037 8.094 8.037c4.462 0 8.095-3.605 8.095-8.037 0-4.357-3.707-8.037-8.095-8.037z" />
      <path d="M43.437 23.051c0 .568-.463 1.029-1.036 1.029a1.034 1.034 0 0 1-1.038-1.029c0-.568.465-1.029 1.038-1.029s1.036.461 1.036 1.029" />
      <path d="M42.718 23.863a.88.88 0 0 1-1.136-.497l-7.33-20.237a.871.871 0 0 1 .549-1.145c.274-.087 1.512-.57 1.711-.778l.116-.121 1.418 1.425s-1.887.545-1.691 1.186l6.864 19.04a.871.871 0 0 1-.501 1.127" />
      <path d="M39.622 15.223l-1.641.614-.77-1.844a.835.835 0 0 1 .229-.932l.959-.841 1.223 3.003m-13.339 7.923a2.786 2.786 0 0 1-2.902 2.661 2.782 2.782 0 0 1-2.68-2.883 2.784 2.784 0 0 1 2.903-2.66 2.781 2.781 0 0 1 2.679 2.882" />
      <path d="M23.47 23.802a.987.987 0 0 1-.809-.631l-3.97-10.606.094-.251-2.662-7.163c-.19-.508-.127-1.096.385-1.286.51-.189.818.038 1.009.546l2.707 7.297.335.228 3.954 10.549a.98.98 0 0 1-1.043 1.317" />
      <path d="M13.322 3.624a.801.801 0 0 1-.096-.627.791.791 0 0 1 .765-.579l6.794.015a.784.784 0 1 1 .26 1.526l-4.152 1.123a3.253 3.253 0 0 1-3.571-1.458m11.435 24.83a.49.49 0 0 1-.374-.353l-1.039-3.791a.493.493 0 0 1 .349-.606.5.5 0 0 1 .611.347l1.038 3.791a.494.494 0 0 1-.585.612" />
      <path d="M25.86 27.588h-2.037a.677.677 0 0 0-.679.674c0 .371.305.673.679.673h2.037a.676.676 0 0 0 .678-.673.676.676 0 0 0-.678-.674" />
      <polygon points="10.748 23.922 23.492 23.922 23.492 22.18 10.748 22.18" />
      <polyline points="11.37 23.65 10.096 22.452 19.151 11.918 20.425 13.116 11.37 23.65" />
      <path d="M38.09 9.613c-4.364 3.834-8.896 8.796-13.527 14.426l-2.142-2.93 3.974-4.647.792-.924 1.501-1.711 3.695-4.205.521-.445 3.374-2.875 1.812 3.311m.799-8.316c0 .716-.585 1.297-1.306 1.297a1.301 1.301 0 0 1-1.306-1.297c0-.716.584-1.297 1.306-1.297.721 0 1.306.581 1.306 1.297" />
      <path d="M35.428 7.422l1.297 3.518a.412.412 0 0 0 .521.245l1.579-.544a.407.407 0 0 0 .25-.528L37.748 6.57a.412.412 0 0 0-.528-.241l-1.549.569a.407.407 0 0 0-.243.524m-.959 3.108l-2.38 2.409-2.03 2.052-2.921 2.959-.743-1.488-.585-1.173a.853.853 0 0 1 .117-.931L30.9 8.583a.861.861 0 0 1 1.215-.088l2.354 2.035M10.396 33.359C4.665 33.359 0 28.728 0 23.035c0-5.692 4.665-10.323 10.396-10.323 2.725 0 5.315 1.072 7.297 3.02 1.999 1.965 3.101 4.559 3.101 7.303 0 5.693-4.664 10.324-10.398 10.324zm0-18.361c-4.462 0-8.094 3.605-8.094 8.037s3.632 8.037 8.094 8.037c4.464 0 8.095-3.605 8.095-8.037 0-4.356-3.707-8.037-8.095-8.037z" />
      <path d="M11.434 23.035c0 .569-.464 1.029-1.038 1.029-.572 0-1.036-.46-1.036-1.029 0-.568.464-1.029 1.036-1.029.574 0 1.038.461 1.038 1.029m39.957 11.917l-.016-.002a6.066 6.066 0 0 0-3.51.668l-8.973 4.747a1.838 1.838 0 0 1-.865.215H25.313v-.169c0-.495.402-.897.897-.897h5.458a4.034 4.034 0 0 0 4.034-4.034c0-.641-.52-1.16-1.161-1.16H18.704c-1.45 0-2.831.62-3.795 1.703l-4.769 5.362v11.94h3.826c1.559 0 3.111-.222 4.609-.66l21.538-6.291a7.374 7.374 0 0 0 2.409-1.219l9.653-7.38c1.131-.864.63-2.666-.784-2.823M8.747 41.255H.405A.405.405 0 0 0 0 41.66v14.289c0 .224.181.406.405.406h8.342a.405.405 0 0 0 .405-.406V41.66a.405.405 0 0 0-.405-.405zM5.904 54.513a1.105 1.105 0 1 1 0-2.21 1.105 1.105 0 0 1 0 2.21z" />
    </g>
  ),
  viewBox: '0 0 54 57',
});

export const HotelFeatureRepairIcon = createIcon({
  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path d="M19.191 11.426A5.37 5.37 0 1 0 16.755.966a5.37 5.37 0 0 0 2.436 10.46m18.772 12.573a1.91 1.91 0 0 0-.981-1.809v3.34c.545-.302.932-.863.981-1.531" />
      <path d="M30.374 44.805h.017a6.197 6.197 0 0 0-1.972 4.564c0 3.373 2.628 6.108 5.869 6.108 3.242 0 5.868-2.735 5.868-6.108a6.196 6.196 0 0 0-1.972-4.565c-.183-.21-1.68-2.03-1.68-5.588V18.844s-.256-4.053.956-6.113c2.43-1.351 3.862-4.087 3.357-6.881-.416-2.303-2.045-4.072-4.137-4.813l.91 5.034c.082.453-1.268 2.124-3.046 2.472-1.778.348-3.632-.774-3.701-1.152l-.91-5.033c-1.691 1.481-2.591 3.744-2.174 6.047.368 2.036 1.684 3.652 3.428 4.513 1.087 2.352.885 5.907.885 5.907v20.372c0 3.79-1.698 5.608-1.698 5.608zm1.779 1.399a.803.803 0 0 0 .569-.342l.268-.432a.408.408 0 0 1 .529-.147l.44.234a.778.778 0 0 0 .657 0l.441-.234a.408.408 0 0 1 .529.147l.268.432c.11.178.366.332.569.342l.494.026a.43.43 0 0 1 .388.403l.024.514c.01.211.158.477.328.592l.417.28c.17.114.233.362.141.55l-.226.458a.873.873 0 0 0 0 .684l.226.458a.448.448 0 0 1-.141.551l-.417.279a.854.854 0 0 0-.328.592l-.024.514a.43.43 0 0 1-.388.403l-.494.026a.805.805 0 0 0-.569.342l-.268.432a.41.41 0 0 1-.53.148l-.44-.235a.785.785 0 0 0-.657 0l-.44.235a.409.409 0 0 1-.529-.148l-.268-.432a.807.807 0 0 0-.569-.342l-.495-.026a.427.427 0 0 1-.386-.403l-.026-.514a.854.854 0 0 0-.328-.592l-.415-.279a.445.445 0 0 1-.142-.551l.225-.458a.873.873 0 0 0 0-.684l-.225-.458a.444.444 0 0 1 .142-.55l.415-.28a.854.854 0 0 0 .328-.592l.026-.514a.427.427 0 0 1 .386-.403l.495-.026z" />
      <path d="M1.125 46.003h8.064v-2.134h.834l-.755-6.8H7.667l-.869-3.087c.173-.291.272-.63.268-.992l-.001-.34c.006-7.627 1.862-11.512 3.477-13.5.337-.414.666-.746.976-1.019v34.448a2.951 2.951 0 0 0 5.902 0V33.18h1.139v19.399a2.952 2.952 0 0 0 5.903 0V26.815c0-.141-.014-.28-.033-.416v-5.702a17.918 17.918 0 0 0 7.166 4.255v-4.051a14.162 14.162 0 0 1-4.555-2.991 14.606 14.606 0 0 1-2.033-2.471 11.31 11.31 0 0 1-.459-.771 6.156 6.156 0 0 1-.115-.225l-.002-.005a1.924 1.924 0 0 0-.398-.567 1.843 1.843 0 0 0-1.449-.704h-9.221c-.154 0-.302.02-.445.056a1.906 1.906 0 0 0-.349.04v-.001c-.291.057-2.703.664-4.989 3.476-2.291 2.808-4.341 7.683-4.335 15.912l.002.38c.003.349.102.673.268.953l-.868 3.086H1.045l-.755 6.8h.835v2.134zm3.099-11.328a1.9 1.9 0 0 0 .932.245h.021c.332-.004.643-.092.913-.244l.673 2.393H3.551l.673-2.394z" />
    </g>
  ),
  viewBox: '0 0 41 56',
});

export const HotelFeatureToolsIcon = createIcon({
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.78 12.769l2.548-2.805-1.559-8.757H5.05L3.49 9.964l2.549 2.805v14.139H.582v3.791a3.91 3.91 0 0 1 0 7.819v12.498a11.693 11.693 0 0 0 15.654 0V38.518a3.91 3.91 0 0 1 0-7.819v-3.791H10.78V12.769M38.214.862v8.976a2.33 2.33 0 0 1-2.329 2.331h-6.088a2.331 2.331 0 0 1-2.332-2.331V.862a11.102 11.102 0 0 0-5.734 9.723 11.11 11.11 0 0 0 5.679 9.692V48.59a5.43 5.43 0 1 0 10.859 0V20.277a11.104 11.104 0 0 0 5.679-9.692c0-4.186-2.314-7.829-5.734-9.723zM32.84 50.34a2.443 2.443 0 1 1 .003-4.887 2.443 2.443 0 0 1-.003 4.887z"
    />
  ),
  viewBox: '0 0 44 55',
});

export const MerchantMapMarkerIcon = createIcon({
  path: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="map-marker-alt" fillRule="nonzero">
        <path
          d="M12.7855156,37.2333203 C2.00167969,21.599957 0,19.9954961 0,14.25 C0,6.37991797 6.37991797,0 14.25,0 C22.120082,0 28.5,6.37991797 28.5,14.25 C28.5,19.9954961 26.4983203,21.599957 15.7144844,37.2333203 C15.0068086,38.2556094 13.4931172,38.2555352 12.7855156,37.2333203 Z"
          id="Path"
          fill="currentColor"
        />
        <path
          d="M14.25,20.1875 C17.529207,20.1875 20.1875,17.529207 20.1875,14.25 C20.1875,10.970793 17.529207,8.3125 14.25,8.3125 C10.970793,8.3125 8.3125,10.970793 8.3125,14.25 C8.3125,17.529207 10.970793,20.1875 14.25,20.1875 Z"
          id="Path"
          fill="#FFFFFF"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 29 38',
});
