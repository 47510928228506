import { gql } from '@apollo/client';

export const ProductTeaserFragment = gql`
  fragment ProductTeaserFragment on Product {
    id
    title
    url
    isbn
    downloadText
    downloadPassword
    externalLink
    subtitle
    price
    productCategory {
      id
      name
    }
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
      productImage: imageStyle(name: PRODUCT_TEASER) {
        url
        width
        height
      }
    }
    body
  }
`;

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    title
    url
    isbn
    downloadText
    downloadPassword
    externalLink
    subtitle
    price
    gpxFiles {
      url
    }
    images {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }

      full: imageStyle(name: FULL) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      teaserHuge: imageStyle(name: TEASER_HUGE) {
        url
      }
      teaserSquare: imageStyle(name: TEASER_SQUARE) {
        url
      }
    }
    productCategory {
      id
      name
    }
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
      productImage: imageStyle(name: PRODUCT_TEASER) {
        url
      }
    }
    body

    productContent: content {
      ...ParagraphActivityListFragment
      ...ParagraphActivityPoiTeaserListFragment
      ...ParagraphFromLibrary
      ...ParagraphColumnsFragment
      ...ParagraphHotelListFragment
      ...ParagraphImageFragment
      ...ParagraphImageTextTeaserFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphLinksFragment
      ...ParagraphNewsListFragment
      ...ParagraphProductListFragment
      ...ParagraphTextFragment
    }

    productsInRegion {
      total
      items {
        ...ProductTeaserFragment
      }
    }
  }

  ${ProductTeaserFragment}
`;
