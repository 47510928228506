import { gql } from '@apollo/client';
import { ParagraphActivityPoiTeaserListFragment } from './components/Paragraph/ActivityPoiTeaserList/fragment';
import { ParagraphHotelOffersFragment } from './components/Paragraph/HotelOffers/fragment';
import { ParagraphHeroFragment } from './components/Paragraph/Hero/fragment';
import { PageFragment } from './components/Node/Page/fragment';
import { ParagraphActivitySliderFragment } from './components/Paragraph/ActivitySlider/fragment';
import { ParagraphFormularFragment } from './components/Paragraph/Formular/fragment';
import { ParagraphRegionTeaserListFragment } from './components/Paragraph/RegionTeaserList/fragment';
import {
  HotelFragment,
  HotelMapTeaserFragment,
  HotelTeaserFragment,
} from './components/Node/Hotel/fragment';
import {
  ActivityFragment,
  ActivityMapTeaserFragment,
  ActivityTeaserFragment,
} from './components/Node/Activity/fragment';
import {
  PoiFragment,
  PoiMapTeaserFragment,
  PoiTeaserFragment,
} from './components/Node/Poi/fragment';
import { MerchantTeaserFragment } from './components/Node/Merchant/fragment';
import { RatingFragment } from './components/Node/Hotel/HotelRatingList';
import { MenuFragment } from './components/Menu/fragment';
import { NewsFragment } from './components/Node/News/fragment';
import { ProductFragment } from './components/Node/Product/fragment';
import { ParagraphColumnsFragment } from './components/Paragraph/Columns/fragment';
import { ParagraphLinksFragment } from './components/Paragraph/Links/fragment';
import { ParagraphTextFragment } from './components/Paragraph/Text/fragment';
import { ParagraphImageAndTextFragment } from './components/Paragraph/ImageAndText/fragment';
import { ParagraphContainerFragment } from './components/Paragraph/Container/fragment';
import { ParagraphImageFragment } from './components/Paragraph/Image/fragment';
import { ParagraphImageTextTeaserFragment } from './components/Paragraph/ImageTextTeaser/fragment';
import { ParagraphColumnFragment } from './components/Paragraph/Column/fragment';
import { ParagraphInfolinoRatingFragment } from './components/Paragraph/InfolinoRating/fragment';
import { ParagraphInfolinoContactFragment } from './components/Paragraph/InfolinoContact/fragment';
import { ParagraphInfolinoPOIsFragment } from './components/Paragraph/InfolinoPOIs/fragment';
import { ParagraphInfolinoActivitiesFragment } from './components/Paragraph/InfolinoActivities/fragment';
import { ParagraphInfolinoWellnessFormularFragment } from './components/Paragraph/InfolinoWellnessFormular/fragment';
import { ParagraphInfolinoFeedbackFormularFragment } from './components/Paragraph/InfolinoFeedbackFormular/fragment';
import { CommentFragment } from './graphql/fragments/Comment';
import { PassTableDataFragment } from './components/Paragraph/PassTable/fragment';
import { ParagraphFromLibraryFragment } from './components/Paragraph/FromLibrary/fragment';
import { PersonFragment } from './components/Node/Person/fragment';
import { AdSlotFragment } from './graphql/fragments/AdSlot';
import { ParagraphPartnerListFragment } from './components/Paragraph/PartnerList/fragment';

export const MetaTagsFragment = gql`
  fragment MetaTagsFragment on Metatag {
    tag
    attributes {
      name
      content
      rel
      href
      property
    }
  }
`;

export const TermCategoryFragment = gql`
  fragment TermCategoryFragment on TermCategory {
    id
    labelPlural
    name
    key
    frontendUrl
    question
    image {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
    }
    searchPageUrl(frontend: $frontend)
    groupSearchPageUrl(frontend: $frontend)
  }
`;

export const MediaImageFragment = gql`
  fragment MediaImageFragment on MediaImage {
    id
    name
    copyright
    imageTitle
    imageAlt
    url
    width
    height
    useNameAsCaption
  }
`;

export const CREATE_RATING_QUERY = gql`
  mutation CreateRatingMutation($data: CreateRatingInput) {
    createRating(data: $data) {
      created
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_COMMENT_QUERY = gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      errors
      created
    }
  }
`;

export const CREATE_ORDER_QUERY = gql`
  mutation CreateOrderMutation($data: CreateOrderInput) {
    createOrder(data: $data) {
      created
      errors {
        field
        message
      }
    }
  }
`;

export const GET_FILTERS_QUERY = gql`
  query GetFiltersQuery(
    $frontend: String!
    $category: String!
    $filter: FilterInputGrouped
  ) {
    filters: autocomplete {
      __typename
      countries(
        searchQuery: ""
        frontend: $frontend
        category: $category
        filter: $filter
        limit: 2000
        sort: { field: "name", direction: ASC }
      ) {
        __typename
        items {
          id
          name
        }
      }

      regions(
        searchQuery: ""
        frontend: $frontend
        category: $category
        filter: $filter
        limit: 2000
        sort: { field: "name", direction: ASC }
      ) {
        __typename
        items {
          id
          name
          country {
            id
          }
        }
      }

      features(
        searchQuery: ""
        frontend: $frontend
        category: $category
        filter: $filter
        limit: 2000
        sort: { field: "name", direction: ASC }
      ) {
        __typename
        items {
          ... on TermHotelFeature {
            id
            name
          }
          ... on TermActivityFeature {
            id
            name
          }
        }
      }
    }
  }
`;

export const AUTOCOMPLETE_QUERY = gql`
  query AutoCompleteQuery(
    $searchQuery: String!
    $frontend: String!
    $category: String!
  ) {
    autocomplete {
      __typename
      result(
        searchQuery: $searchQuery
        frontend: $frontend
        category: $category
        limit: 5
        sort: { field: "popularity", direction: DESC }
      ) {
        __typename
        items {
          ... on EntityInterface {
            id
            title
          }

          ... on Hotel {
            url
          }

          ... on Activity {
            url
          }
        }
      }
      countries(
        searchQuery: $searchQuery
        frontend: $frontend
        category: $category
        limit: 5
      ) {
        __typename
        items {
          id
          name
        }
      }
      regions(
        searchQuery: $searchQuery
        frontend: $frontend
        category: $category
        limit: 5
      ) {
        __typename
        total
        items {
          id
          name
        }
      }
      # postcodes(
      #   searchQuery: $searchQuery
      #   frontend: $frontend
      #   category: $category
      #   limit: 5
      # ) {
      #   __typename
      #   items {
      #     id
      #     name
      #   }
      # }
      # cities(
      #   searchQuery: $searchQuery
      #   frontend: $frontend
      #   category: $category
      #   limit: 5
      # ) {
      #   __typename
      #   total
      #   items {
      #     id
      #     name
      #   }
      # }
      geocodeResults(searchQuery: $searchQuery) {
        __typename
        features {
          id
          geometry {
            coordinates
          }
          properties {
            city
            country
            name
            state
            postcode
          }
        }
      }
    }
  }
`;

export const CREATE_BOOKING_QUERY = gql`
  mutation CreateBookingMutation($data: CreateBookingInput) {
    createBooking(data: $data) {
      created
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_INFOLINO_FEEDBACK_QUERY = gql`
  mutation CreateFeedbackMutation($data: CreateInfolinoFeedbackInput) {
    createInfolinoFeedback(data: $data) {
      created
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_INFOLINO_WELLNESS_QUERY = gql`
  mutation CreateWellnessMutation($data: CreateInfolinoWellnessInput) {
    createInfolinoWellness(data: $data) {
      created
      errors {
        field
        message
      }
    }
  }
`;

export const ADMIN_MENU_QUERY = gql`
  query AdminMenuQuery($frontend: String!) {
    adminMenu: menu(id: "admin") {
      ...MenuFragment
    }
  }

  ${MenuFragment}
`;

export const TeaserQuery = gql`
  query TeaserQuery($route: String!, $frontend: String!) {
    route(path: $route, frontend: $frontend) {
      entity {
        ...HotelTeaserFragment
        ...ActivityTeaserFragment
        ...PoiTeaserFragment
      }
    }
  }

  ${TermCategoryFragment}
  ${MediaImageFragment}
  ${ActivityTeaserFragment}
  ${HotelTeaserFragment}
  ${PoiTeaserFragment}
`;

export const IDQuery = gql`
  query IDQuery($frontend: String!, $id: Int!) {
    idQuery(frontend: $frontend, id: $id)
  }
`;

export const RouteQuery = gql`
  query RouteQuery(
    $route: String!
    $frontend: String!
    $menuFooter: String!
    $menuFooterMain: String!
    $menuMain: String!
    $searchParameters: SearchParameters
    $includeFooterMain: Boolean!
    $isMapQuery: Boolean!
    $activitiesInRegionFilter: FilterInputGrouped
    $passTableParameters: PassTableParameters
    $passMapParameters: PassMapParameters
    $passListMapParameters: PassListMapParameters
  ) {
    config(frontend: $frontend) {
      id
      defaultActivityImage {
        ...MediaImageFragment
        teaser: imageStyle(name: TEASER) {
          url
        }
      }
    }
    footerMenu: menu(id: $menuFooter) {
      ...MenuFragment
    }
    footerMenuMain: menu(id: $menuFooterMain) @include(if: $includeFooterMain) {
      ...MenuFragment
    }
    mainMenu: menu(id: $menuMain) {
      ...MenuFragment
    }
    redirect(path: $route, frontend: $frontend) {
      statusCode
      destination
    }
    route(path: $route, frontend: $frontend) {
      entity {
        ...PageFragment
        ...HotelFragment
        ...ActivityFragment
        ...PoiFragment
        ...NewsFragment
        ...ProductFragment
        ...PersonFragment
      }
    }
    adSlotTerms {
      ...AdSlot
    }
    footerParagraphs(frontend: $frontend) {
      ...ParagraphFromLibrary
      ...ParagraphPartnerList
    }
  }

  ${AdSlotFragment}
  ${PersonFragment}
  ${MenuFragment}
  ${ProductFragment}
  ${RatingFragment}
  ${ActivityFragment}
  ${ActivityTeaserFragment}
  ${MerchantTeaserFragment}
  ${PoiFragment}
  ${NewsFragment}
  ${PoiTeaserFragment}
  ${HotelFragment}
  ${HotelTeaserFragment}
  ${PageFragment}
  ${ParagraphActivityPoiTeaserListFragment}
  ${ParagraphHeroFragment}
  ${ParagraphHotelOffersFragment}
  ${ParagraphActivitySliderFragment}
  ${ParagraphFormularFragment}
  ${ParagraphRegionTeaserListFragment}
  ${MediaImageFragment}
  ${TermCategoryFragment}
  ${ActivityMapTeaserFragment}
  ${HotelMapTeaserFragment}
  ${PoiMapTeaserFragment}
  ${MetaTagsFragment}
  ${ParagraphFromLibraryFragment}
  ${ParagraphPartnerListFragment}
`;

export const FulltextSearchQuery = gql`
  query FulltextSearchQuery(
    $query: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterInputGrouped
    $frontend: String!
    $menuFooter: String!
    $menuMain: String!
  ) {
    config(frontend: $frontend) {
      id
      defaultActivityImage {
        ...MediaImageFragment
        teaser: imageStyle(name: TEASER) {
          url
        }
      }
    }
    footerMenu: menu(id: $menuFooter) {
      ...MenuFragment
    }
    mainMenu: menu(id: $menuMain) {
      ...MenuFragment
    }
    search {
      result(
        searchQuery: $query
        offset: $offset
        limit: $limit
        filter: $filter
      ) {
        total
        items {
          ...HotelTeaserFragment
          ...ActivityTeaserFragment
          ...PoiTeaserFragment
        }
      }
    }
  }

  ${MediaImageFragment}
  ${MenuFragment}
  ${ActivityTeaserFragment}
  ${HotelTeaserFragment}
  ${PoiTeaserFragment}
  ${TermCategoryFragment}
`;

export const SearchByCategoryMapQuery = gql`
  query SearchByCategoryMapQuery(
    $category: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterInputGrouped
    $frontend: String!
    $menuFooter: String!
    $menuMain: String!
    $sort: Sort!
  ) {
    config(frontend: $frontend) {
      defaultActivityImage {
        ...MediaImageFragment
        teaser: imageStyle(name: TEASER) {
          url
        }
      }
    }
    footerMenu: menu(id: $menuFooter) {
      ...MenuFragment
    }
    mainMenu: menu(id: $menuMain) {
      ...MenuFragment
    }
    searchByCategory(category: $category, frontend: $frontend) {
      categories(frontend: $frontend) {
        id
        labelPlural
        name
        question
        frontendUrl
        key
      }
      category {
        id
        labelPlural
        name
        question
        frontendUrl
        key
      }
      result(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
        total
        items {
          ...HotelMapTeaserFragment
          ...ActivityMapTeaserFragment
          ...PoiMapTeaserFragment
        }
        activeFilters {
          label
          field
          value
        }
      }
    }
    #    ???  NOT SURE WE NEED THIS ???
    #    filters: autocomplete {
    #      __typename
    #      countries(
    #        searchQuery: ""
    #        frontend: $frontend
    #        category: $category
    #        filter: $filter
    #        limit: 2000
    #        sort: { field: "name", direction: ASC }
    #      ) {
    #        __typename
    #        items {
    #          id
    #          name
    #        }
    #      }
    #
    #      regions(
    #        searchQuery: ""
    #        frontend: $frontend
    #        category: $category
    #        filter: $filter
    #        limit: 2000
    #        sort: { field: "name", direction: ASC }
    #      ) {
    #        __typename
    #        items {
    #          id
    #          name
    #          country {
    #            id
    #          }
    #        }
    #      }
    #    }
  }

  ${MediaImageFragment}
  ${MenuFragment}
  ${ActivityMapTeaserFragment}
  ${HotelMapTeaserFragment}
  ${PoiMapTeaserFragment}
`;

export const SearchByCategoryQuery = gql`
  query SearchByCategoryQuery(
    $category: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterInputGrouped
    $frontend: String!
    $menuFooter: String!
    $menuMain: String!
    $sort: Sort!
  ) {
    config(frontend: $frontend) {
      defaultActivityImage {
        ...MediaImageFragment
        teaser: imageStyle(name: TEASER) {
          url
        }
      }
    }
    footerMenu: menu(id: $menuFooter) {
      ...MenuFragment
    }
    mainMenu: menu(id: $menuMain) {
      ...MenuFragment
    }
    searchByCategory(category: $category, frontend: $frontend) {
      categories(frontend: $frontend) {
        id
        labelPlural
        name
        question
        frontendUrl
        key
      }
      category {
        id
        labelPlural
        name
        question
        frontendUrl
        key
      }
      result(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
        total
        items {
          ...HotelTeaserFragment
          ...ActivityTeaserFragment
          ...PoiTeaserFragment
        }
        activeFilters {
          label
          field
          value
        }
      }
    }
  }

  ${MediaImageFragment}
  ${MenuFragment}
  ${ActivityTeaserFragment}
  ${HotelTeaserFragment}
  ${PoiTeaserFragment}
  ${TermCategoryFragment}
`;

export const InfolinoHotelFragment = gql`
  fragment InfolinoHotelFragment on Hotel {
    title
    id
    activitiesFreizeithotels {
      ...ActivityTeaserFragment
    }
    poisFreizeithotels {
      ...PoiTeaserFragment
    }
  }
`;

export const InfolinoQuery = gql`
  query InfolinoQuery($path: String!, $frontend: String!) {
    infolino(path: $path) {
      baseUrl
      hotel {
        ...InfolinoHotelFragment
      }
      logo {
        url
      }
      imageBorder
      color
      page {
        title
        url
        isHomepage
        content {
          ... on ParagraphInterface {
            id
          }
          ...ParagraphColumnsFragment
          ...ParagraphLinksFragment
          ...ParagraphTextFragment
          ...ParagraphImageAndTextFragment
          ...ParagraphContainerFragment
          ...ParagraphImageTextTeaserFragment
          ...ParagraphImageFragment
          ...ParagraphInfolinoContactFragment
          ...ParagraphInfolinoRatingFragment
          ...ParagraphInfolinoActivitiesFragment
          ...ParagraphInfolinoPOIsFragment
          ...ParagraphInfolinoWellnessFormularFragment
          ...ParagraphInfolinoFeedbackFormularFragment
        }
      }
      menu {
        title
        isHomepage
        url
        image {
          url
          derivative: imageStyle(name: INLINE) {
            url
          }
        }
      }
    }
  }

  ${ParagraphColumnsFragment}
  ${ParagraphLinksFragment}
  ${ParagraphTextFragment}
  ${ParagraphContainerFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphImageFragment}
  ${MediaImageFragment}
  ${TermCategoryFragment}
  ${ParagraphColumnFragment}
  ${ParagraphInfolinoRatingFragment}
  ${ParagraphInfolinoContactFragment}
  ${ActivityTeaserFragment}
  ${PoiTeaserFragment}
  ${ParagraphInfolinoPOIsFragment}
  ${ParagraphInfolinoActivitiesFragment}
  ${InfolinoHotelFragment}
  ${ParagraphInfolinoWellnessFormularFragment}
  ${ParagraphInfolinoFeedbackFormularFragment}
  ${ParagraphFromLibraryFragment}
  ${MetaTagsFragment}
  ${ParagraphHeroFragment}
  ${MerchantTeaserFragment}
  ${RatingFragment}
  ${HotelTeaserFragment}
`;

export const QueryLoadComments = gql`
  query LoadComments($entityId: Int!, $offset: Int!) {
    loadMoreComments(entityId: $entityId, offset: $offset) {
      ...Comment
    }
  }

  ${CommentFragment}
`;

export const QueryLoadMorePassTable = gql`
  query LoadMorePassTable(
    $paragraphId: Int!
    $parameters: PassTableParameters
  ) {
    loadMorePassTable(paragraphId: $paragraphId, parameters: $parameters) {
      ...PassTableData
    }
  }

  ${PassTableDataFragment}
`;
