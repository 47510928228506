import { gql } from '@apollo/client';

export const ParagraphColumnsFragment = gql`
  fragment ParagraphColumnsFragment on ParagraphColumns {
    content {
      ... on ParagraphInterface {
        id
      }
      ...ParagraphColumnFragment
    }
  }
`;
