import {
  Hotel,
  Query,
  RatingConnection,
  Rating,
  HotelFragmentFragment,
  RatingFragmentFragment,
} from '../../../generated/types';
import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import RatingItem from './RatingItem';
import { gql, useLazyQuery } from '@apollo/client';

export const RatingFragment = gql`
  fragment RatingFragment on Rating {
    id
    title
    body
    reply
    ratingKindness
    ratingHousing
    ratingCatering
    email
    lastname
    firstname
    publicName
    dateRange {
      value
      end_value
    }
    hotel {
      url
      title
    }
  }
`;

export const RatingQuery = gql`
  query HotelRatingQuery($id: Int!, $limit: Int!, $offset: Int!) {
    getHotelById(id: $id) {
      ratings(limit: $limit, offset: $offset) {
        total
        items {
          ...RatingFragment
        }
      }
    }
  }

  ${RatingFragment}
`;

interface HotelRatingListProps {
  data: {
    total: number;
    items?: RatingFragmentFragment[];
  } | null;
  hotel: Hotel | HotelFragmentFragment;
}

const HotelRatingList: React.FC<HotelRatingListProps> = ({ data, hotel }) => {
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [hasMoreRatings, setHasMoreRatings] = useState(false);
  const [getMoreRatings, { data: queryData }] = useLazyQuery<Query>(
    RatingQuery
  );

  // Set ratings after data is available
  useEffect(() => {
    if (data) {
      setRatings(data.items as Rating[]);
      setHasMoreRatings((data.items as Rating[]).length < data.total);
    }
  }, [data]);

  useEffect(() => {
    if (queryData) {
      const newRatings = [
        ...ratings,
        ...queryData.getHotelById!.ratings!.items!,
      ];
      setRatings(newRatings as Rating[]);
      setHasMoreRatings(
        newRatings.length < queryData!.getHotelById!.ratings!.total
      );
    }
  }, [queryData]);

  const loadMoreRatings = async () => {
    getMoreRatings({
      variables: {
        id: hotel.id,
        offset: ratings.length,
        limit: 5,
      },
    });
    return;
  };

  return (
    <>
      {ratings.length > 0 ? (
        <Stack marginTop={4} spacing={12} paddingTop={4}>
          {ratings.map((rating: any, i: number) => (
            <RatingItem
              key={rating.id}
              rating={rating}
              isLast={i === ratings.length - 1}
            />
          ))}
          {hasMoreRatings && (
            <Flex justifyContent="center" marginTop={4}>
              <Button onClick={loadMoreRatings}>Mehr Bewertungen laden</Button>
            </Flex>
          )}
        </Stack>
      ) : (
        <Box>Noch keine Bewertungen</Box>
      )}
    </>
  );
};

export default HotelRatingList;
