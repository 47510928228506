import { gql } from '@apollo/client';

export const ParagraphTestimonialFragment = gql`
  fragment ParagraphTestimonialFragment on ParagraphTestimonial {
    id
    image {
      ...MediaImageFragment
      big: imageStyle(name: HERO_LARGE) {
        url
      }
    }
    data(frontend: $frontend) {
      ...RatingFragment
    }
  }
`;
