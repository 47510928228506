import { gql } from '@apollo/client';
import { AdSlotFragment } from '../../../graphql/fragments/AdSlot';

export const ParagraphAdSlotFragment = gql`
  fragment ParagraphAdSlot on ParagraphAdSlot {
    id
    slot {
      ...AdSlot
    }
  }
  ${AdSlotFragment}
`;
