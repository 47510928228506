import { gql } from '@apollo/client';

export const CommentFragment = gql`
  fragment Comment on Comment {
    id
    thread
    subject
    username
    created
    comment
    parentComment {
      id
    }
    images {
      url
      teaser: imageStyle(name: MEDIUM) {
        url
        width
        height
      }
    }
    closures
    stars
    tipps
    vehicle
    dateVisit
  }
`;
