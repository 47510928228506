import { Box, Heading, Flex, Stack } from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Rating } from '../../../generated/types';
import { CustomStarFilled } from '../../../config/icons';
import parseDateRange from '../../../utils/parseDateRange';

interface RatingItemProps {
  rating: Rating;
  isLast: boolean;
}

export default function RatingItem({ rating, isLast }: RatingItemProps) {
  const starColor = 'starColor';

  return (
    <>
      <Box
        key={rating.id}
        paddingBottom={isLast ? 0 : 6}
        borderBottom={isLast ? 0 : '1px'}
        borderColor="gray.100"
      >
        <Flex
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          marginBottom={4}
          alignItems={{
            base: 'flex-start',
            md: 'center',
          }}
        >
          <Box
            marginBottom={{
              base: 2,
              md: 0,
            }}
            flex={1}
          >
            <Box fontWeight="bold" wordBreak="break-all">
              {rating.publicName
                ? rating.publicName
                : `${rating.firstname} ${rating.lastname}`}
            </Box>
            {rating.dateRange &&
              rating.dateRange.value &&
              rating.dateRange.end_value && (
                <Box fontSize="sm" marginTop={2}>
                  Aufenthaltszeitraum:{' '}
                  {parseDateRange(
                    rating.dateRange.value,
                    rating.dateRange.end_value
                  )}
                </Box>
              )}
          </Box>
          <Stack fontSize="sm" isInline spacing={12}>
            <Flex flexDir="column" align="center" justify="center">
              <Flex fontWeight="bold" alignItems="center">
                <CustomStarFilled marginRight={1} fill="starColor" />
                {rating.ratingCatering}
              </Flex>
              <Box>Verpflegung</Box>
            </Flex>
            <Flex flexDir="column" align="center">
              <Flex fontWeight="bold" alignItems="center">
                <CustomStarFilled marginRight={1} fill="starColor" />
                {rating.ratingHousing}
              </Flex>
              <Box>Unterkunft</Box>
            </Flex>
            <Flex flexDir="column" align="center">
              <Flex fontWeight="bold" alignItems="center">
                <CustomStarFilled marginRight={1} fill="starColor" />
                {rating.ratingKindness}
              </Flex>
              <Box>Freundlichkeit</Box>
            </Flex>
          </Stack>
        </Flex>
        <Box>
          {rating.body ? (
            <div
              dangerouslySetInnerHTML={{
                __html: rating.body!,
              }}
            />
          ) : (
            <Box fontSize="sm" color="gray.500">
              Es wurde kein Kommentar abgegeben.
            </Box>
          )}
        </Box>
        {rating.reply && (
          <Box
            marginTop={2}
            bg="gray.50"
            border="1px"
            borderColor="gray.200"
            p={4}
          >
            <Heading size="md" as="span">
              Antwort vom Hotel
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                __html: rating.reply,
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
