import { gql } from '@apollo/client';

export const ParagraphRegionTeaserListFragment = gql`
  fragment ParagraphRegionTeaserListFragment on ParagraphRegionTeaserList {
    id
    regionTeaserListItems {
      ... on ParagraphRegionTeaserListItem {
        region {
          id
          name
          body
          location {
            lat
            lng
          }
          image {
            ...MediaImageFragment
            teaser: imageStyle(name: TEASER) {
              url
            }
            teaserLarge: imageStyle(name: TEASER_LARGE) {
              url
            }
          }
        }
        linkText
        linkUrl(frontend: $frontend)
      }
    }
  }
`;
