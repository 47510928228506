import { gql } from '@apollo/client';
import { PoiTableFragment } from '../../Node/Poi/fragment';

export const PassListMapDataFragment = gql`
  fragment PassListMapData on PoiConnection {
    total
    items {
      ...PoiTable
    }
    countries {
      id
      name
    }
    regions {
      id
      name
      country {
        id
      }
    }
  }

  ${PoiTableFragment}
`;

export const ParagraphPassListMapFragment = gql`
  fragment ParagraphPassListMap on ParagraphPassListMap {
    id
    title
    data(parameters: $passListMapParameters) {
      ...PassListMapData
    }
    regions {
      id
    }
    countries {
      id
    }
  }

  ${PassListMapDataFragment}
`;
