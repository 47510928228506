import { TvvCoreAppConfig } from '../pages/_app';
import EbikeAtlasConfig, { theme as EbikeAtlasTheme } from './ebikeatlas';
import FreizeithotelsConfig, {
  theme as FreizeithotelsTheme,
} from './freizeithotels';
import BikeratlasConfig, { theme as BikeratlasTheme } from './bikeratlas';
import BikerbettenConfig, { theme as BikerbettenTheme } from './bikerbetten';
import InfolinoConfig, { theme as InfolinoTheme } from './infolino';
import { Dict } from '@chakra-ui/utils';

export interface AppConfig {
  config: TvvCoreAppConfig;
  theme: Dict;
}

const apps: {
  [key: string]: AppConfig;
} = {
  ebikeatlas: {
    config: EbikeAtlasConfig,
    theme: EbikeAtlasTheme,
  },
  freizeithotels: {
    config: FreizeithotelsConfig,
    theme: FreizeithotelsTheme,
  },
  bikeratlas: {
    config: BikeratlasConfig,
    theme: BikeratlasTheme,
  },
  bikerbetten: {
    config: BikerbettenConfig,
    theme: BikerbettenTheme,
  },
  infolino: {
    config: InfolinoConfig,
    theme: InfolinoTheme,
  },
};

export default apps;
