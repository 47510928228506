import { gql } from '@apollo/client';

export const MerchantTeaserFragment = gql`
    fragment MerchantTeaserFragment on Merchant {
        id
        title
        street
        city
        postcode
        email
        phone
        homepage
        company
        location {
            lat
            lng
        }
    }
`;
