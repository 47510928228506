import { gql } from '@apollo/client';

export const NewsTeaserFragment = gql`
  fragment NewsTeaserFragment on News {
    id
    title
    created
    url(frontend: $frontend)
    frontend {
      name
    }
    category {
      ...TermCategoryFragment
    }
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
    }

    teaserText
  }
`;
