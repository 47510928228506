import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Dict } from '@chakra-ui/utils';

export const NewComponentTheme = {
  GeolocationInputButton: {
    baseStyle: {
      width: '2.5rem',
      h: 'calc(2.5rem - 4px)',
      border: 0,
      boxShadow: 'none',
      bg: 'transparent',
      borderRadius: 0,
      _hover: {
        color: 'gray.900',
        bg: 'gray.200',
      },
    },
  },
  Input: {
    parts: ['field'],
    variants: {
      outline: {
        field: {
          borderWidth: '2px',
          borderColor: 'brand.700',
          boxShadow: '#58b300 0 0 0 2px',
          paddingX: 8,
          paddingY: 1,
          lineHeight: 'none',
          borderRadius: '3xl',
          bg: '#fff',
        },
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: '3xl',
    },
    variants: {
      outline: {
        borderWidth: '2px',
        borderColor: 'brand.700',
        boxShadow: '#58b300 0 0 0 2px',
        paddingX: 8,
        paddingY: 1,
        fontWeight: 'bold',
        lineHeight: 'none',
        cursor: 'pointer',
        _hover: {
          bg: 'brand.700',
          color: '#fff',
        },
        _active: {
          bg: 'brand.700',
          color: '#fff',
        },
      },
    },
    defaultProps: {
      variant: 'outline',
    },
  },
};

const theme: Dict = extendTheme({
  styles: {
    global: (props: Dict) => {
      return {
        '*, *::before, &::after': {
          borderColor: mode('gray.300', 'whiteAlpha.300')(props),
        },
        body: {
          color: 'textColorBase',
        },
        '.text-align-center': {
          textAlign: 'center',
        },
        '.highlighted': {
          color: 'textColor',
        },
        '.leaflet-container a': {
          color: 'textColor',
        },
        '.html-text': {
          h1: {
            fontSize: {
              base: '2xl',
              md: '3xl',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
          h2: {
            fontSize: {
              base: 'xl',
              md: '2xl',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
          h3: {
            fontSize: {
              base: 'xl',
              md: 'xl',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
          h4: {
            fontSize: {
              base: 'lg',
              md: 'xl',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
          h5: {
            fontSize: {
              base: 'md',
              md: 'lg',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
          h6: {
            fontSize: {
              base: 'lg',
              md: 'xl',
            },
            fontFamily: props.theme.fonts.heading,
            lineHeight: 'normal',
          },
        },
      };
    },
  },
  breakpoints: {
    containerWidth: '1344px',
    '2xl': '1536px',
  },
  colors: {
    iconHotel: '#c53030',
    iconHiking: '#1f792f',
    iconEbike: '#58b300',
    iconBike: '#91bd18',
    iconRiding: '#89320d',
    iconKanu: '#134270',
    iconSwim: '#2d88d3',
    iconSki: '#564d4a',
    iconMotorcycle: '#d21f27',
    iconGolf: '#f57c15',
    iconMountainbike: '#3ca63e',
    textColorBase: '#333',
    starColor: '#D69E2E',
  },
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Rubik, serif',
    mono: "'Open Sans', sans-serif",
  },
  zIndices: {
    overlay: 9998,
    modal: 9999,
  },
  components: {
    GeolocationInputButton: {
      baseStyle: {
        width: '2.5rem',
        h: 'calc(2.5rem - 2px)',
        bg: 'gray.50',
        borderLeftWidth: '1px',
        borderLeftColor: 'gray.300',
        _hover: {
          color: 'gray.900',
          bg: 'gray.200',
        },
        borderLeftRadius: 0,
      },
    },
    Link: {
      baseStyle: {
        color: 'blue.600',
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
      },
    },
    // Input: {
    //     variants: {
    //         // outline: (props) => {
    //         //     return {
    //         //         field: {
    //         //             // border: "1px solid",
    //         //             // borderColor: 'red.500',
    //         //         }
    //         //     };
    //         // }
    //     },
    // },
  },
});

export default theme;
