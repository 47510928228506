import { gql } from '@apollo/client';

export const ParagraphInfolinoWellnessFormularFragment = gql`
  fragment ParagraphInfolinoWellnessFormularFragment on ParagraphInfolinoWellnessFormular {
    id
    email
    treatments
    description
  }
`;
