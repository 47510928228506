import { gql } from '@apollo/client';

export const OfferForFrontendItemFragment = gql`
  fragment OfferForFrontendItemFragment on Offers {
    id
    body
    title
    subtitle
    price
    image {
      ...MediaImageFragment
      imageStyle(name: TEASER) {
        url
      }
      teaser: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
    dateRange {
      value
      end_value
    }
    hotel {
      region {
        name
      }
      country {
        name
      }
      teaserImage {
        ...MediaImageFragment
        teaser: imageStyle(name: IMAGE_TEXT_TEASER) {
          url
        }
      }
      url
      title
    }
  }
`;

const OfferItemHotelFragment = gql`
  fragment OfferItemHotelFragment on Hotel {
    id
    title
    url
    region {
      name
    }
    country {
      name
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
  }
`;

const OfferItemFragent = gql`
  fragment OfferItemFragment on Offers {
    id
    price
    subtitle
    image {
      ...MediaImageFragment
      teaser: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
    title
    body
    hotel {
      ...OfferItemHotelFragment
    }
  }

  ${OfferItemHotelFragment}
`;

export const ParagraphHotelOffersFragment = gql`
  fragment ParagraphHotelOffersFragment on ParagraphHotelOffers {
    title
    subtitle
    data(frontend: $frontend) {
      items {
        ... on Offers {
          ...OfferItemFragment
        }
      }
      total
    }
  }

  ${OfferItemFragent}
`;
