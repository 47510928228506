import { gql } from '@apollo/client';
import { ParagraphAdSlotFragment } from '../AdSlot/fragment';
import { ParagraphCommentBoxFragment } from '../CommentBox/fragment';
import { ParagraphCommentListAndFormularFragment } from '../CommentListAndFormular/fragment';
import { ParagraphFaqFragment } from '../Faq/fragment';
import { ParagraphInfoBoxFragment } from '../InfoBox/fragment';
import { ParagraphSearchByIdFragment } from '../SearchById/fragment';

export const ParagraphContainerFragment = gql`
  fragment ParagraphContainerFragment on ParagraphContainer {
    size
    content {
      ... on ParagraphInterface {
        id
      }
      ...ParagraphTextFragment
      ...ParagraphColumnsFragment
      ...ParagraphImageFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphLinksFragment
      ...ParagraphFromLibrary
      ...ParagraphCommentListAndFormular
      ...ParagraphSearchById
      ...ParagraphInfoBox
      ...ParagraphCommentBox
      ...ParagraphAuthors
      ...ParagraphPartner
      ...ParagraphAdSlot
      ...ParagraphFaq
    }
  }

  ${ParagraphFaqFragment}
  ${ParagraphInfoBoxFragment}
  ${ParagraphCommentBoxFragment}
  ${ParagraphCommentListAndFormularFragment}
  ${ParagraphSearchByIdFragment}
  ${ParagraphAdSlotFragment}
`;
