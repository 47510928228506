import { gql } from '@apollo/client';

export const ParagraphActivitySliderFragment = gql`
    fragment ParagraphActivitySliderFragment on ParagraphActivitySlider {
        id
        title
        subtitle
        data(frontend: $frontend) {
            total
            items {
                ...ActivityTeaserFragment
            }
        }
    }
`;
