import { gql } from '@apollo/client';

export const ParagraphFaqFragment = gql`
  fragment ParagraphFaq on ParagraphFaq {
    id
    title
    hideFrontend
    questions {
      question
      answer
    }
  }
`;
