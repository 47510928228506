import { gql } from '@apollo/client';

export const ParagraphTextFragment = gql`
  fragment ParagraphTextFragment on ParagraphText {
    id
    body
    headline
    addToTableOfContents
  }
`;
