import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ADMIN_MENU_QUERY } from '../../queries';
import { useStore } from '../../store/provider';

const AdminBar = () => {
  const store = useStore();
  const { loading, error, data } = useQuery(ADMIN_MENU_QUERY, {
    skip: !store.user.token,
    variables: {
      frontend: store.config.frontendName,
    },
  });

  return null;

  return (
    <Flex
      zIndex={9999}
      bg="#fff"
      p={2}
      pos="fixed"
      top="0"
      left="0"
      right="0"
      height="60"
    >
      hi {store.user.token}
    </Flex>
  );
};

export default observer(AdminBar);
