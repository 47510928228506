import { gql } from '@apollo/client';
import { CommentFragment } from '../../../graphql/fragments/Comment';
import { PartnerFragment } from '../../../graphql/fragments/Partner';
import { ParagraphActivityListFragment } from '../../Paragraph/ActivityList/fragment';
import { ParagraphActivityPoiActivitiesInRegionFragment } from '../../Paragraph/ActivityPoiActivitiesInRegion/fragment';
import { ParagraphActivityPoiImagesFragment } from '../../Paragraph/ActivityPoiImages/fragment';
import { ParagraphActivityPoiMapFragment } from '../../Paragraph/ActivityPoiMap/fragment';
import { ParagraphActivityPoiNearActivitiesFragment } from '../../Paragraph/ActivityPoiNearActivities/fragment';
import { ParagraphActivityPoiNearPoisFragment } from '../../Paragraph/ActivityPoiNearPois/fragment';
import { ParagraphActivityPoiPoisInRegionFragment } from '../../Paragraph/ActivityPoiPoisInRegion/fragment';
import { ParagraphActivityPoiProductsInRegionFragment } from '../../Paragraph/ActivityPoiProductsInRegion/fragment';
import { ParagraphActivityPoiTeaserListFragment } from '../../Paragraph/ActivityPoiTeaserList/fragment';
import { ParagraphActivitySliderFragment } from '../../Paragraph/ActivitySlider/fragment';
import { ParagraphAdSlotFragment } from '../../Paragraph/AdSlot/fragment';
import { ParagraphAuthorsFragment } from '../../Paragraph/Authors/fragment';
import { ParagraphColumnsFragment } from '../../Paragraph/Columns/fragment';
import { ParagraphCommentBoxFragment } from '../../Paragraph/CommentBox/fragment';
import { ParagraphCommentListAndFormularFragment } from '../../Paragraph/CommentListAndFormular/fragment';
import { ParagraphCustomTeaserListFragment } from '../../Paragraph/CustomTeaserList/fragment';
import { ParagraphFaqFragment } from '../../Paragraph/Faq/fragment';
import { ParagraphFormularFragment } from '../../Paragraph/Formular/fragment';
import { ParagraphFromLibraryFragment } from '../../Paragraph/FromLibrary/fragment';
import { ParagraphHotelListFragment } from '../../Paragraph/HotelList/fragment';
import { ParagraphImageFragment } from '../../Paragraph/Image/fragment';
import { ParagraphImageAndTextFragment } from '../../Paragraph/ImageAndText/fragment';
import { ParagraphImageTextTeaserFragment } from '../../Paragraph/ImageTextTeaser/fragment';
import { ParagraphInfoBoxFragment } from '../../Paragraph/InfoBox/fragment';
import { ParagraphLinksFragment } from '../../Paragraph/Links/fragment';
import { ParagraphNewsListFragment } from '../../Paragraph/NewsList/fragment';
import { ParagraphPartnerFragment } from '../../Paragraph/Partner/fragment';
import { ParagraphProductListFragment } from '../../Paragraph/ProductList/fragment';
import { ParagraphRegionTeaserListFragment } from '../../Paragraph/RegionTeaserList/fragment';
import { ParagraphSidebarBlockFragment } from '../../Paragraph/SidebarBlock/fragment';
import { ParagraphTextFragment } from '../../Paragraph/Text/fragment';
import { PersonFragment, PersonTeaserFragment } from '../Person/fragment';
import { PoiTeaserFragment } from '../Poi/fragment';
import { ProductTeaserFragment } from '../Product/fragment';

export const ActivityMapTeaserFragment = gql`
  fragment ActivityMapTeaserFragment on Activity {
    id
    url
    category {
      key
    }
    location {
      lat
      lng
    }
  }
`;

export const ActivityTeaserFragment = gql`
  fragment ActivityTeaserFragment on Activity {
    id
    url
    title
    gpx {
      url
    }
    length
    duration
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      teaserTextImage: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
    body
    country {
      name
    }
    region {
      name
    }
    category {
      ...TermCategoryFragment
    }
    location {
      lat
      lng
    }

    authors {
      ...PersonTeaserFragment
    }
    partner {
      ...PartnerFragment
    }
  }

  ${PersonTeaserFragment}
  ${PartnerFragment}
`;

export const ActivityFragment = gql`
  fragment ActivityFragment on Activity {
    id
    updatedAt
    url
    title
    body
    old_id
    duration
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    length
    gpx {
      url
    }
    isOffroad
    polyline {
      value
    }
    category {
      id
      name
      key
      labelPlural
      frontendUrl
    }
    frontends {
      name
    }
    difficulty {
      name
    }
    country {
      id
      name
    }
    location {
      lat
      lng
    }
    region {
      id
      name
    }
    product {
      ...ProductTeaserFragment
    }
    hotels(frontend: $frontend) {
      total
      items {
        ...HotelTeaserFragment
        activities {
          ...ActivityTeaserFragment
        }
      }
    }
    teaserImage {
      ...MediaImageFragment
      full: imageStyle(name: FULL) {
        url
      }
      big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
        url
      }
      medium: imageStyle(name: PAGE_HEADER_MEDIUM_THUMB) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      small: imageStyle(name: PAGE_HEADER_SMALL_THUMB) {
        url
      }
      teaser: imageStyle(name: TEASER) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
      teaserHuge: imageStyle(name: TEASER_HUGE) {
        url
      }
      teaserSquare: imageStyle(name: TEASER_SQUARE) {
        url
      }
    }
    images {
      ...MediaImageFragment
      full: imageStyle(name: FULL) {
        url
      }
      big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
        url
      }
      medium: imageStyle(name: PAGE_HEADER_MEDIUM_THUMB) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      small: imageStyle(name: PAGE_HEADER_SMALL_THUMB) {
        url
      }
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserHuge: imageStyle(name: TEASER_HUGE) {
        url
      }
      teaserSquare: imageStyle(name: TEASER_SQUARE) {
        url
      }
      teaserSquareSmall: imageStyle(name: TEASER_SQUARE) {
        url
      }
    }
    nearHotels(frontend: $frontend, limit: 3) {
      total
      items {
        ...HotelTeaserFragment
      }
    }
    nearActivities(frontend: $frontend, limit: 3) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }
    nearPois(frontend: $frontend, limit: 3) {
      total
      items {
        ...PoiTeaserFragment
      }
    }
    elevationData
    productsInRegion {
      total
      items {
        ...ProductTeaserFragment
      }
    }
    activitiesInRegion(frontend: $frontend, filter: $activitiesInRegionFilter) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }
    poisInRegion(frontend: $frontend) {
      total
      items {
        ...PoiTeaserFragment
      }
    }
    activityContent: content {
      ...ParagraphTextFragment
      ...ParagraphImageFragment
      ...ParagraphActivityPoiMap
      ...ParagraphActivityPoiPoisInRegion
      ...ParagraphActivityPoiProductsInRegion
      ...ParagraphActivityPoiImages
      ...ParagraphActivityPoiActivitiesInRegion
      ...ParagraphCommentListAndFormular
      ...ParagraphActivitySliderFragment
      ...ParagraphColumnsFragment
      ...ParagraphActivityListFragment
      ...ParagraphActivityPoiTeaserListFragment
      ...ParagraphFormularFragment
      ...ParagraphHotelListFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphImageTextTeaserFragment
      ...ParagraphLinksFragment
      ...ParagraphNewsListFragment
      ...ParagraphProductListFragment
      ...ParagraphRegionTeaserListFragment
      ...ParagraphFromLibrary
      ...ParagraphActivityPoiNearPois
      ...ParagraphActivityPoiNearActivities
      ...ParagraphCustomTeaserList
      ...ParagraphInfoBox
      ...ParagraphCommentBox
      ...ParagraphPartner
      ...ParagraphAuthors
      ...ParagraphAdSlot
      ...ParagraphFaq
    }
    sidebarBlocks {
      ...ParagraphSidebarBlock
    }
    useArticleLayout

    paragraphsBetweenTextAndPhotos: globalParagraphs(
      frontend: $frontend
      slot: "tour_slot_1"
    ) {
      ...ParagraphFromLibrary
      ...ParagraphProductListFragment
      ...ParagraphAdSlot
    }

    totalComments
    comments {
      ...Comment
    }

    authors {
      ...PersonTeaserFragment
    }
    partner {
      ...PartnerFragment
    }
  }

  ${ParagraphFaqFragment}
  ${ParagraphAdSlotFragment}
  ${ParagraphPartnerFragment}
  ${ParagraphAuthorsFragment}
  ${ParagraphInfoBoxFragment}
  ${ParagraphCommentBoxFragment}
  ${PersonTeaserFragment}
  ${PartnerFragment}
  ${CommentFragment}
  ${ParagraphImageFragment}
  ${ParagraphTextFragment}
  ${ParagraphActivityPoiMapFragment}
  ${ParagraphActivityPoiPoisInRegionFragment}
  ${ParagraphActivityPoiImagesFragment}
  ${ParagraphActivityPoiActivitiesInRegionFragment}
  ${ParagraphActivityPoiProductsInRegionFragment}
  ${ParagraphSidebarBlockFragment}
  ${ParagraphCommentListAndFormularFragment}
  ${ParagraphActivitySliderFragment}
  ${ParagraphColumnsFragment}
  ${ParagraphActivityListFragment}
  ${ParagraphActivityPoiTeaserListFragment}
  ${ParagraphFormularFragment}
  ${ParagraphHotelListFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphLinksFragment}
  ${ParagraphNewsListFragment}
  ${ParagraphProductListFragment}
  ${ParagraphRegionTeaserListFragment}
  ${ParagraphFromLibraryFragment}
  ${ProductTeaserFragment}
  ${PoiTeaserFragment}
  ${ParagraphActivityPoiNearPoisFragment}
  ${ParagraphActivityPoiNearActivitiesFragment}
  ${ParagraphCustomTeaserListFragment}
`;
