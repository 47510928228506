import { gql } from '@apollo/client';
import { CommentFragment } from '../../../graphql/fragments/Comment';
import { PartnerFragment } from '../../../graphql/fragments/Partner';
import { ParagraphActivityListFragment } from '../../Paragraph/ActivityList/fragment';
import { ParagraphActivityPoiActivitiesInRegionFragment } from '../../Paragraph/ActivityPoiActivitiesInRegion/fragment';
import { ParagraphActivityPoiImagesFragment } from '../../Paragraph/ActivityPoiImages/fragment';
import { ParagraphActivityPoiMapFragment } from '../../Paragraph/ActivityPoiMap/fragment';
import { ParagraphActivityPoiNearActivitiesFragment } from '../../Paragraph/ActivityPoiNearActivities/fragment';
import { ParagraphActivityPoiNearPoisFragment } from '../../Paragraph/ActivityPoiNearPois/fragment';
import { ParagraphActivityPoiPoisInRegionFragment } from '../../Paragraph/ActivityPoiPoisInRegion/fragment';
import { ParagraphActivityPoiProductsInRegionFragment } from '../../Paragraph/ActivityPoiProductsInRegion/fragment';
import { ParagraphActivityPoiTeaserListFragment } from '../../Paragraph/ActivityPoiTeaserList/fragment';
import { ParagraphActivitySliderFragment } from '../../Paragraph/ActivitySlider/fragment';
import { ParagraphAdSlotFragment } from '../../Paragraph/AdSlot/fragment';
import { ParagraphAuthorsFragment } from '../../Paragraph/Authors/fragment';
import { ParagraphColumnsFragment } from '../../Paragraph/Columns/fragment';
import { ParagraphCommentBoxFragment } from '../../Paragraph/CommentBox/fragment';
import { ParagraphCommentListAndFormularFragment } from '../../Paragraph/CommentListAndFormular/fragment';
import { ParagraphCustomTeaserListFragment } from '../../Paragraph/CustomTeaserList/fragment';
import { ParagraphFaqFragment } from '../../Paragraph/Faq/fragment';
import { ParagraphFormularFragment } from '../../Paragraph/Formular/fragment';
import { ParagraphFromLibraryFragment } from '../../Paragraph/FromLibrary/fragment';
import { ParagraphHotelListFragment } from '../../Paragraph/HotelList/fragment';
import { ParagraphImageFragment } from '../../Paragraph/Image/fragment';
import { ParagraphImageAndTextFragment } from '../../Paragraph/ImageAndText/fragment';
import { ParagraphImageTextTeaserFragment } from '../../Paragraph/ImageTextTeaser/fragment';
import { ParagraphInfoBoxFragment } from '../../Paragraph/InfoBox/fragment';
import { ParagraphLinksFragment } from '../../Paragraph/Links/fragment';
import { ParagraphNewsListFragment } from '../../Paragraph/NewsList/fragment';
import { ParagraphPartnerFragment } from '../../Paragraph/Partner/fragment';
import { ParagraphPoiPassProfileFragment } from '../../Paragraph/PoiPassProfile/fragment';
import { ParagraphProductListFragment } from '../../Paragraph/ProductList/fragment';
import { ParagraphRegionTeaserListFragment } from '../../Paragraph/RegionTeaserList/fragment';
import { ParagraphSidebarBlockFragment } from '../../Paragraph/SidebarBlock/fragment';
import { ParagraphTextFragment } from '../../Paragraph/Text/fragment';
import { PersonFragment, PersonTeaserFragment } from '../Person/fragment';

export const PoiTableFragment = gql`
  fragment PoiTable on Poi {
    id
    url
    title
    country {
      id
      name
      isocode
    }
    region {
      id
      name
    }
    location {
      lat
      lng
    }
    isPass
    category {
      key
    }
    gpx {
      url
    }
    passHeight
    toll
    tollPrices
    unpaved
    length
  }
`;

export const PoiMapTeaserFragment = gql`
  fragment PoiMapTeaserFragment on Poi {
    id
    url
    category {
      key
    }
    location {
      lat
      lng
    }
    gpx {
      url
    }
    isPass
  }
`;

export const PoiTeaserFragment = gql`
  fragment PoiTeaserFragment on Poi {
    id
    url
    title
    isPass
    passHeight
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      teaserTextImage: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
    body
    country {
      name
    }
    region {
      name
    }
    category {
      id
      name
      key
    }
    location {
      lat
      lng
    }

    authors {
      ...PersonTeaserFragment
    }
    partner {
      ...PartnerFragment
    }
  }

  ${PersonTeaserFragment}
  ${PartnerFragment}
`;

export const PoiFragment = gql`
  fragment PoiFragment on Poi {
    id
    url
    updatedAt
    title
    body
    old_id
    isPass
    isOffroad
    duration
    length
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    gpx {
      url
    }
    passHeight
    maxSlope
    winterClosure
    basePlaces
    toll
    tollPrices
    unpaved
    numberOfTurns
    deadEnd
    restrictions
    category {
      name
      key
    }
    frontends {
      name
    }
    difficulty {
      name
    }
    country {
      name
    }
    location {
      lat
      lng
    }
    region {
      name
    }
    elevationData
    hotels(frontend: $frontend) {
      total
      items {
        ...HotelTeaserFragment
      }
    }
    teaserImage {
      ...MediaImageFragment
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
      full: imageStyle(name: FULL) {
        url
      }
      big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
        url
      }
      medium: imageStyle(name: PAGE_HEADER_MEDIUM_NO_SIDEBAR_THUMB) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      small: imageStyle(name: PAGE_HEADER_SMALL_NO_SIDEBAR_THUMB) {
        url
      }
      teaser: imageStyle(name: TEASER) {
        url
      }
    }
    images {
      ...MediaImageFragment
      full: imageStyle(name: FULL) {
        url
      }
      big: imageStyle(name: PAGE_HEADER_BIG_THUMB) {
        url
      }
      medium: imageStyle(name: PAGE_HEADER_MEDIUM_THUMB) {
        url
      }
      tiny: imageStyle(name: PAGE_HEADER_TINY_THUMB) {
        url
      }
      small: imageStyle(name: PAGE_HEADER_SMALL_THUMB) {
        url
      }
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserHuge: imageStyle(name: TEASER_HUGE) {
        url
      }
      teaserSquare: imageStyle(name: TEASER_SQUARE) {
        url
      }
      teaserSquareSmall: imageStyle(name: TEASER_SQUARE) {
        url
      }
    }
    nearHotels(frontend: $frontend, limit: 3) {
      total
      items {
        ...HotelTeaserFragment
      }
    }
    nearActivities(frontend: $frontend, limit: 3) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }
    nearPois(frontend: $frontend, limit: 3) {
      total
      items {
        ...PoiTeaserFragment
      }
    }
    elevationData
    stars

    productsInRegion {
      total
      items {
        ...ProductFragment
      }
    }
    activitiesInRegion(frontend: $frontend, filter: $activitiesInRegionFilter) {
      total
      items {
        ...ActivityTeaserFragment
      }
    }

    poisInRegion(frontend: $frontend) {
      total
      items {
        ...PoiTeaserFragment
      }
    }

    poiContent: content {
      ...ParagraphTextFragment
      ...ParagraphImageFragment
      ...ParagraphActivityPoiMap
      ...ParagraphActivityPoiPoisInRegion
      ...ParagraphActivityPoiProductsInRegion
      ...ParagraphActivityPoiImages
      ...ParagraphActivityPoiActivitiesInRegion
      ...ParagraphCommentListAndFormular
      ...ParagraphActivitySliderFragment
      ...ParagraphColumnsFragment
      ...ParagraphActivityListFragment
      ...ParagraphActivityPoiTeaserListFragment
      ...ParagraphFormularFragment
      ...ParagraphHotelListFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphImageTextTeaserFragment
      ...ParagraphLinksFragment
      ...ParagraphNewsListFragment
      ...ParagraphProductListFragment
      ...ParagraphRegionTeaserListFragment
      ...ParagraphFromLibrary
      ...ParagraphActivityPoiNearPois
      ...ParagraphActivityPoiNearActivities
      ...ParagraphCustomTeaserList
      ...ParagraphPoiPassProfile
      ...ParagraphInfoBox
      ...ParagraphCommentBox
      ...ParagraphPartner
      ...ParagraphAuthors
      ...ParagraphAdSlot
      ...ParagraphFaq
    }
    sidebarBlocks {
      ...ParagraphSidebarBlock
    }
    useArticleLayout

    passParagraphsBetweenTextAndPhotos: globalParagraphs(
      frontend: $frontend
      slot: "pass_slot_1"
    ) {
      ...ParagraphFromLibrary
      ...ParagraphProductListFragment
      ...ParagraphAdSlot
    }

    offroadParagraphsBetweenTextAndPhotos: globalParagraphs(
      frontend: $frontend
      slot: "offroad_slot_1"
    ) {
      ...ParagraphFromLibrary
      ...ParagraphProductListFragment
      ...ParagraphAdSlot
    }

    totalComments
    comments {
      ...Comment
    }

    authors {
      ...PersonTeaserFragment
    }
    partner {
      ...PartnerFragment
    }

    offroadCars
  }

  ${ParagraphFaqFragment}
  ${ParagraphAdSlotFragment}
  ${ParagraphPartnerFragment}
  ${ParagraphAuthorsFragment}
  ${PersonTeaserFragment}
  ${PartnerFragment}
  ${ParagraphInfoBoxFragment}
  ${ParagraphCommentBoxFragment}
  ${CommentFragment}
  ${ParagraphImageFragment}
  ${ParagraphTextFragment}
  ${ParagraphActivityPoiMapFragment}
  ${ParagraphActivityPoiPoisInRegionFragment}
  ${ParagraphActivityPoiImagesFragment}
  ${ParagraphActivityPoiActivitiesInRegionFragment}
  ${ParagraphActivityPoiProductsInRegionFragment}
  ${ParagraphSidebarBlockFragment}
  ${ParagraphCommentListAndFormularFragment}
  ${ParagraphActivitySliderFragment}
  ${ParagraphColumnsFragment}
  ${ParagraphActivityListFragment}
  ${ParagraphActivityPoiTeaserListFragment}
  ${ParagraphFormularFragment}
  ${ParagraphHotelListFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphLinksFragment}
  ${ParagraphNewsListFragment}
  ${ParagraphProductListFragment}
  ${ParagraphRegionTeaserListFragment}
  ${ParagraphFromLibraryFragment}
  ${PoiTeaserFragment}
  ${ParagraphActivityPoiNearPoisFragment}
  ${ParagraphActivityPoiNearActivitiesFragment}
  ${ParagraphCustomTeaserListFragment}
  ${ParagraphPoiPassProfileFragment}
`;
