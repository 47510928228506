import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { API_ENDPOINT, IS_DEVELOPMENT, IS_STAGING } from '../config/constants';
import introspectionResult from '../generated/introspection.json';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { STAGING_HEADERS } from './api';

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(cacheTagsSet: Set<String> | null = null) {
  // Basic Http Link
  const httpLinkWithUpload = createUploadLink({
    uri: `${API_ENDPOINT}/graphql`,
    credentials: 'same-origin',
    includeExtensions: true,
  });

  // Cache Tags
  const cacheTagsAfterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const headers = context.response.headers as Headers;

      if (headers && headers.has('x-drupal-cache-tags') && cacheTagsSet) {
        const cacheTagsHeader = headers.get('x-drupal-cache-tags');

        // Add all tags to the set
        if (cacheTagsHeader) {
          cacheTagsHeader
            .split(' ')
            .forEach((cacheTag) => cacheTagsSet.add(cacheTag));
        }
      }

      return response;
    });
  });

  // Errors
  const errorsLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      console.log('GraphQL ERROR OPERATION', operation, response);
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${JSON.stringify(path)}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  );

  const authLink = setContext((_, { headers }) => {
    const token = Cookies.get('__token');

    let authHeader = '';

    if (token) {
      authHeader = `Bearer ${token}`;
    } else if (IS_STAGING) {
      authHeader = process.env.API_AUTH;
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: authHeader,
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // link: persistedQueriesLink.concat(errorsLink).concat(httpLink),
    link: from([
      authLink,
      errorsLink,
      cacheTagsAfterwareLink,
      httpLinkWithUpload,
    ]),
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: {
        AutocompleteResult: {
          keyFields: [],
        },
      },
    }),
    connectToDevTools: IS_DEVELOPMENT,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

export function initializeApollo(
  initialState: any = null,
  cacheTagsSet: Set<String> | null = null
) {
  const _apolloClient = apolloClient ?? createApolloClient(cacheTagsSet);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
