import { gql } from '@apollo/client';

export const ParagraphMerchantListFragment = gql`
  fragment ParagraphMerchantListFragment on ParagraphMerchantList {
    id
    merchants(frontend: $frontend) {
      total
      items {
        ...MerchantTeaserFragment
      }
    }
  }
`;
