import { gql } from '@apollo/client';

export const ParagraphCustomTeaserListItemFragment = gql`
  fragment ParagraphCustomTeaserListItem on ParagraphCustomTeaserListItem {
    image {
      ...MediaImageFragment
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
    }
    title
    body
    linkText
    linkUrl(frontend: $frontend)
  }
`;

export const ParagraphCustomTeaserListFragment = gql`
  fragment ParagraphCustomTeaserList on ParagraphCustomTeaserList {
    id
    items: content {
      ...ParagraphCustomTeaserListItem
    }
  }

  ${ParagraphCustomTeaserListItemFragment}
`;
