import { gql } from '@apollo/client';
import { PartnerFragment } from '../../../graphql/fragments/Partner';

export const ParagraphPartnerListFragment = gql`
  fragment ParagraphPartnerList on ParagraphPartnerList {
    id
    title
    partner {
      ...PartnerFragment
    }
  }
  ${PartnerFragment}
`;
