import { gql } from '@apollo/client';

export const ParagraphHeaderFragment = gql`
  fragment ParagraphHeaderFragment on ParagraphHeader {
    useNegativeLogoVersion
    showAddon
    image {
      ...MediaImageFragment
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
    }
    headline
    linkText
    linkUrl(frontend: $frontend)
    regions {
      id
    }
    countries {
      id
    }
  }
`;
