import { gql } from '@apollo/client';

export const ParagraphFormularFragment = gql`
    fragment ParagraphFormularFragment on ParagraphFormular {
        id
        formular
        textConfirm
        textError
    }
`;
