import { gql } from '@apollo/client';

export const ParagraphActivityPoiTeaserListFragment = gql`
    fragment ParagraphActivityPoiTeaserListFragment on ParagraphActivityPoiTeaserList {
        id
        title
        subtitle
        teaser {
            id
            title
            termReference {
                id
                name
                frontendUrl
                key
                image {
                    ...MediaImageFragment
                    teaser: imageStyle(name: TEASER) {
                        url
                    }
                }
            }
        }
    }
`;
