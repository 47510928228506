import { gql } from '@apollo/client';
import { NewsTeaserFragment } from '../../Node/News/teaserFragment';

export const ParagraphNewsListFragment = gql`
  fragment ParagraphNewsListFragment on ParagraphNewsList {
    id
    data(frontend: $frontend) {
      items {
        ...NewsTeaserFragment
      }
    }
  }

  ${NewsTeaserFragment}
`;
