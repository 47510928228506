import { gql } from '@apollo/client';
import { TermCategoryFragment } from '../../../queries';

export const ParagraphSearchResultsFragment = gql`
  fragment ParagraphSearchResultsFragment on ParagraphSearchResults {
    result(parameters: $searchParameters) {
      total
      items {
        ...HotelMapTeaserFragment @include(if: $isMapQuery)
        ...ActivityMapTeaserFragment @include(if: $isMapQuery)
        ...PoiMapTeaserFragment @include(if: $isMapQuery)

        ...HotelTeaserFragment @skip(if: $isMapQuery)
        ...ActivityTeaserFragment @skip(if: $isMapQuery)
        ...PoiTeaserFragment @skip(if: $isMapQuery)
      }
      activeFilters {
        label
        field
        value
      }
    }
  }
`;
