import { gql } from '@apollo/client';
import { ParagraphActivityPoiNearHotelsFragment } from '../ActivityPoiNearHotels/fragment';
import { ParagraphAdSlotFragment } from '../AdSlot/fragment';
import { ParagraphImageTextTeaserFragment } from '../ImageTextTeaser/fragment';
import { ParagraphLinksFragment } from '../Links/fragment';
import { ParagraphTextFragment } from '../Text/fragment';

export const ParagraphSidebarBlockFragment = gql`
  fragment ParagraphSidebarBlock on ParagraphSidebarBlock {
    id
    title
    content {
      ...ParagraphActivityPoiNearHotels
      ...ParagraphImageTextTeaserFragment
      ...ParagraphLinksFragment
      ...ParagraphTextFragment
      ...ParagraphAdSlot
    }
  }

  ${ParagraphActivityPoiNearHotelsFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphLinksFragment}
  ${ParagraphTextFragment}
  ${ParagraphAdSlotFragment}
`;
