import { gql } from '@apollo/client';

export const ParagraphEyeCatcherFragment = gql`
  fragment ParagraphEyeCatcherFragment on ParagraphEyeCatcher {
    id
    image {
      ...MediaImageFragment
      big: imageStyle(name: HERO_LARGE) {
        url
      }
    }
    headline
    body
    alignment
    contentImage {
      ...MediaImageFragment
      inline: imageStyle(name: INLINE_ORIGINAL) {
        url
      }
    }
    linkText
    linkUrl
  }
`;
