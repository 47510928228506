import { gql } from '@apollo/client';

export const ParagraphActivityTabsFragment = gql`
  fragment ParagraphActivityTabsFragment on ParagraphActivityTabs {
    id
    title
    subtitle
    limitPerCategory
    categories {
      title
      category {
        ...TermCategoryFragment
      }
    }
    categoryItems(frontend: $frontend) {
      category {
        ...TermCategoryFragment
      }
      items {
        ...ActivityTeaserFragment
      }
    }
  }
`;
