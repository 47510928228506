import { TvvCoreAppConfig } from '../../pages/_app';
import { Dict } from '@chakra-ui/utils';

/**
 * https://themera.vercel.app/
 * https://smart-swatch.netlify.app/
 */
export const theme: Dict = {
  colors: {
    textColor: '#b92d2d',
    brand: {
      50: '#ffe6e6',
      100: '#f5bfbf',
      200: '#e89797',
      300: '#dd6e6f',
      400: '#d24646',
      500: '#b92d2d',
      600: '#912222',
      700: '#681718',
      800: '#410c0d',
      900: '#1d0101',
    },
  },
};

const config: TvvCoreAppConfig = {
  gaId: 'G-K0NY9ERDFR',
  usesNewLayout: false,
  fullUrl: 'https://www.freizeithotels.info',
  siteName: 'Freizeithotels.info',
  frontendId: 'freizeithotels',
  frontendName: 'Freizeithotels',
  menuMain: 'freizeithotels-main',
  menuFooter: 'freizeithotels-footer',
  embedEnabled: true,
  matomoId: '1',
  styleConfig: {
    googleFonts: [
      'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap',
    ],
  },
  renderConfig: {
    activity: {
      renderSidebarAdTeaser: false,
      nearHotels: false,
      nearActivities: false,
      nearPois: true,
      hotelActivities: true,
      hotelActivitiesOther: true,
    },
    hotel: {
      renderSidebarAdTeaser: false,
      nearActivities: false,
      nearPois: true,
    },
    poi: {
      renderSidebarAdTeaser: false,
      nearHotels: true,
      nearActivities: true,
      nearPois: true,
    },
    freizeithotelsTeaser: false,
  },
};

export default config;
