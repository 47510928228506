import { gql } from '@apollo/client';
import { TermCategoryFragment } from '../../../queries';

export const ParagraphSearchbarFragment = gql`
  fragment ParagraphSearchbarFragment on ParagraphSearchbar {
    headline
    showFilters
    showMapButton
    categories {
      title
      category {
        ...TermCategoryFragment
      }
    }
    categoriesLinkToSearchPage
    showGroupSearch
    groupSearchTitle
    groupSearchButtonTitle
  }
`;
