import Cookies from 'js-cookie';
import { action, computed, makeObservable, observable } from 'mobx';
import api from '../utils/api';
import qs from 'qs';

export enum AuthCheckMode {
  RedirectGuests,
  RedirectLoggedIn,
}

interface Userdata {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  meta?: object;
}

export default class User {
  token: string | null = null;
  tokenExpiresAt: number | null = null;
  refreshToken: string | null = null;
  isLoaded: boolean = false;
  isLoading: boolean = false;
  userdata: Userdata | null = null;

  constructor() {
    makeObservable(this, {
      token: observable,
      tokenExpiresAt: observable,
      refreshToken: observable,
      isLoaded: observable,
      userdata: observable,
      isLoading: observable,
      logout: action,
      login: action,
      handleLoginResponse: action,
    });
  }

  logout() {
    Cookies.remove('__token');
    this.token = null;
  }

  handleLoginResponse(data: any) {
    this.token = data.access_token;
    this.refreshToken = data.refresh_token;
    const now = Date.now();
    this.tokenExpiresAt = now + data.expires_in * 1000;

    Cookies.set('__token', this.token!, {
      expires: data.expires_in / 60 / 60 / 24,
    });
  }

  async refreshOauthToken(data: any) {
    this.isLoading = true;
    try {
      const response = await api.post(
        '/oauth/token',
        qs.stringify({
          grant_type: 'refresh_token',
          client_id: '078ff8b0-e335-4e30-a4f3-97c3cb24044f',
          client_secret: 'test123',
          refresh_token: this.refreshToken,
        })
      );

      if (response.data.access_token) {
        this.handleLoginResponse(response.data);

        return response.data;
      }

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      throw new Error(err);
    }
  }

  async login(data: any) {
    this.isLoading = true;
    try {
      const response = await api.post(
        '/oauth/token',
        qs.stringify({
          grant_type: 'password',
          client_id: '078ff8b0-e335-4e30-a4f3-97c3cb24044f',
          client_secret: 'test123',
          ...data,
        })
      );

      if (response.data.access_token) {
        this.handleLoginResponse(response.data);

        return response.data;
      }

      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      throw new Error(err);
    }
  }
}
