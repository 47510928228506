import { gql } from '@apollo/client';

export const ParagraphImageFragment = gql`
  fragment ParagraphImageFragment on ParagraphImage {
    id
    viewmode
    image {
      ...MediaImageFragment
      teaser: imageStyle(name: INLINE) {
        url
      }
      original: imageStyle(name: INLINE_ORIGINAL) {
        url
      }
    }
    caption
  }
`;
