import {
  action,
  observable,
  computed,
  runInAction,
  makeObservable,
} from 'mobx';
import {
  ImageDerivative,
  MediaImage,
  RouteQueryQuery,
} from '../generated/types';
import { TvvCoreAppConfig } from '../pages/_app';
import User from './user';
import Search from './search';

export interface TvvCoreBackendConfig {
  defaultActivityImage:
    | (MediaImage & { teaser: ImageDerivative })
    | null
    | undefined;
}

export interface SimpleLatLng {
  lat: number;
  lng: number;
}

export interface StoreData {}

export default class Store {
  useNegativeLogoVersion: Boolean = false;
  elevationPoint: SimpleLatLng | null = null;
  config: TvvCoreAppConfig;
  backendConfig: TvvCoreBackendConfig;
  user: User;
  search: Search;

  constructor(
    data: any,
    config: TvvCoreAppConfig,
    backendConfig: TvvCoreBackendConfig
  ) {
    this.config = config;
    this.backendConfig = backendConfig;
    this.user = new User();
    this.search = new Search();

    makeObservable(this, {
      useNegativeLogoVersion: observable,
      elevationPoint: observable,
      setUseNegativeLogoVersion: action,
      setElevationPoint: action,
    });
  }

  setUseNegativeLogoVersion(value: Boolean = true) {
    this.useNegativeLogoVersion = value;
  }

  setElevationPoint(point: SimpleLatLng | null) {
    this.elevationPoint = point;
  }
}
