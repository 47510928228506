import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import https from 'https';
import { API_ENDPOINT, IS_DEVELOPMENT, IS_STAGING } from '../config/constants';

const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: {
    query: false,
  },
});

const geocoderCache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: {
    query: false,
  },
});

export const geocoderAxiosInstance = axios.create({
  baseURL: 'https://geocoder.eakroko.com',
  adapter: geocoderCache.adapter,
  params: {
    lang: 'de',
  },
});

geocoderAxiosInstance.interceptors.request.use((config) => {
  const params = new URLSearchParams();
  params.append('lang', 'de');

  if (config.url === '/api') {
    // Set default params for geocoding (but not for reverse)
    params.append('bbox', '-15.1,35.38,33.62,58.7');
    params.append('osm_tag', 'place');
    params.append('osm_tag', '!place:house');
    params.append('osm_tag', 'tourism');
    params.append('lon', '10');
    params.append('lat', '51');
    params.append('location_bias_scale', '0.9');
  }

  if (config.params) {
    Object.keys(config.params).forEach((key) => {
      params.append(key, config.params[key]);
    });
  }

  config.params = params;

  return config;
});

const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});

export const STAGING_HEADERS = {
  Authorization: process.env.API_AUTH,
};

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  httpsAgent,
  adapter: IS_DEVELOPMENT ? undefined : cache.adapter,
  headers: IS_STAGING ? STAGING_HEADERS : {},
});

export const axiosInstanceWithoutAuth = axios.create({
  baseURL: API_ENDPOINT,
  httpsAgent,
  adapter: IS_DEVELOPMENT ? undefined : cache.adapter,
});

export default axiosInstance;
