import { gql } from '@apollo/client';

export const ParagraphHotelListFragment = gql`
  fragment ParagraphHotelListFragment on ParagraphHotelList {
    id
    limit
    category {
      ...TermCategoryFragment
    }
    data(frontend: $frontend) {
      items {
        ...HotelTeaserFragment
      }
    }
    linktext
    regions {
      id
    }
    countries {
      id
    }
  }
`;
