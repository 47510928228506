import { gql } from '@apollo/client';

export const ParagraphActivityListFragment = gql`
  fragment ParagraphActivityListFragment on ParagraphActivityList {
    id
    title
    subtitle
    limit
    category {
      ...TermCategoryFragment
    }
    data(frontend: $frontend) {
      items {
        ...ActivityTeaserFragment
      }
    }
    linktext
    regions {
      id
    }
    countries {
      id
    }
  }
`;
