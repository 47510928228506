import { gql } from '@apollo/client';

export const AdSlotFragment = gql`
  fragment AdSlot on TermAdSlot {
    slotId
    format
    responsive
    maxHeight
    layout
    key
  }
`;
