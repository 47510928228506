export default {
  htmlTexts: false,
  embedded: false,
  groupByPurpose: true,
  storageMethod: 'cookie',
  cookieName: 'klaro',
  cookieExpiresAfterDays: 120,
  privacyPolicy: '/datenschutz',
  default: false,
  mustConsent: true,
  acceptAll: true,
  hideDeclineAll: true,
  hideLearnMore: false,
  hideToggleAll: true,
  noticeAsModal: false,
  lang: 'de',
  apps: [
    {
      name: 'matomo',
      default: true,
      title: 'Matomo',
      purposes: ['analytics'],
      cookies: [[/^_pk_.*$/, '/', 'localhost'], 'piwik_ignore'],
      callback: function (consent: boolean) {
        if (consent == true) {
          (window as any)._paq.push(['rememberCookieConsentGiven']);
        } else {
          (window as any)._paq.push(['forgetCookieConsentGiven']);
        }

        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).gtag = function () {
          (window as any).dataLayer.push(arguments);
        };
        (window as any).gtag('consent', 'update', {
          analytics_storage: consent ? 'granted' : 'denied',
        });
      },
      required: false,
      optOut: false,
      onlyOnce: true,
    },
    {
      name: 'facebook_social',
      default: false,
      title: 'Facebook Social Plugins',
      purposes: ['functional'],
      cookies: [[/^_pk_.*$/, '/', 'localhost'], 'piwik_ignore'],
      callback: function (consent: boolean) {
        if (consent == true) {
          (window as any)._paq.push(['rememberCookieConsentGiven']);
        } else {
          (window as any)._paq.push(['forgetCookieConsentGiven']);
        }
      },
      required: false,
      optOut: false,
      onlyOnce: true,
    },
  ],
  translations: {
    de: {
      privacyPolicyUrl: '/datenschutz',
      matomo: {
        description: 'Sammeln von Besucherstatistiken',
      },
      facebook_social: {
        description: '',
      },
      purposes: {
        analytics: 'Analyse/Statistiken',
        functional: 'Funktionell',
      },
    },
  },
};
