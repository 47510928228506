import { gql } from '@apollo/client';
import { CommentFragment } from '../../../graphql/fragments/Comment';

export const ParagraphCommentListAndFormularFragment = gql`
  fragment ParagraphCommentListAndFormular on ParagraphCommentListAndFormular {
    id
    headline
    totalComments
    comments {
      ...Comment
    }
  }

  ${CommentFragment}
`;
