import { gql } from '@apollo/client';

export const ParagraphImageTextTeaserFragment = gql`
  fragment ParagraphImageTextTeaserFragment on ParagraphImageTextTeaser {
    title
    subtitle
    image {
      ...MediaImageFragment
      teaser: imageStyle(name: IMAGE_TEXT_TEASER) {
        url
      }
    }
    body
    linkText
    customLink(frontend: $frontend)
    category {
      ...TermCategoryFragment
    }
  }
`;
