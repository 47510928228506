import React, { createContext, useContext } from 'react';
import Store, { TvvCoreBackendConfig } from './store';
import { enableStaticRendering } from 'mobx-react-lite';
import { TvvCoreAppConfig } from '../pages/_app';

enableStaticRendering(typeof window === 'undefined');

const StoreContext = createContext<Store | null>(null);

export const useStore = () => {
    const store = useContext(StoreContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    return store;
};

function useClassStore(init: () => Store) {
    const [store] = React.useState(init);
    return store;
}

export default function StoreProvider({
    children,
    initialStoreData,
    config,
    backendConfig,
}: {
    children?: React.ReactNode;
    initialStoreData: object;
    config: TvvCoreAppConfig;
    backendConfig: TvvCoreBackendConfig;
}) {
    const store = useClassStore(
        () => new Store(initialStoreData, config, backendConfig)
    );

    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
}
