export const IS_DEVELOPMENT = process.env.NODE_ENV !== 'production';
export const IS_STAGING = process.env.APP_ENV === 'staging';

export const IS_SERVER = typeof window === 'undefined';

export const API_ENDPOINT = process.env.API;
export const CDN_URL = process.env.CDN;

export const NEAR_DEFAULT_RADIUS = 50000;
export const REVALIDATE_TIMEOUT = 300;
export const USER_SELECTED_LOCATION_ON_MAP_TEXT = 'Ort auf der Karte';

export const RATINGS_MAP: {
  [index: string]: string;
} = {
  popularity: 'Beliebtheit',
  average_rating: 'Beste Bewertung',
  rating_count: 'Am meisten Bewertungen',
  title: 'Titel',
};
