import { gql } from '@apollo/client';
import { PersonTeaserFragment } from '../../Node/Person/fragment';

export const ParagraphCommentBoxFragment = gql`
  fragment ParagraphCommentBox on ParagraphCommentBox {
    author {
      ...PersonTeaserFragment
    }
    externalAuthor
    customLink
    text
    title
  }

  ${PersonTeaserFragment}
`;
