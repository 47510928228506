import { gql } from '@apollo/client';
import { ParagraphActivityListFragment } from '../ActivityList/fragment';
import { ParagraphActivityPoiActivitiesInRegionFragment } from '../ActivityPoiActivitiesInRegion/fragment';
import { ParagraphActivityPoiImagesFragment } from '../ActivityPoiImages/fragment';
import { ParagraphActivityPoiMapFragment } from '../ActivityPoiMap/fragment';
import { ParagraphActivityPoiPoisInRegionFragment } from '../ActivityPoiPoisInRegion/fragment';
import { ParagraphActivityPoiProductsInRegionFragment } from '../ActivityPoiProductsInRegion/fragment';
import { ParagraphActivityPoiTeaserListFragment } from '../ActivityPoiTeaserList/fragment';
import { ParagraphActivitySliderFragment } from '../ActivitySlider/fragment';
import { ParagraphActivityTabsFragment } from '../ActivityTabs/fragment';
import { ParagraphAdSlotFragment } from '../AdSlot/fragment';
import { ParagraphColumnFragment } from '../Column/fragment';
import { ParagraphColumnsFragment } from '../Columns/fragment';
import { ParagraphCommentListAndFormularFragment } from '../CommentListAndFormular/fragment';
import { ParagraphContainerFragment } from '../Container/fragment';
import { ParagraphEyeCatcherFragment } from '../EyeCatcher/fragment';
import { ParagraphFormularFragment } from '../Formular/fragment';
import { ParagraphHotelListFragment } from '../HotelList/fragment';
import { ParagraphHotelOffersFragment } from '../HotelOffers/fragment';
import { ParagraphImageFragment } from '../Image/fragment';
import { ParagraphImageAndTextFragment } from '../ImageAndText/fragment';
import { ParagraphImageTextTeaserFragment } from '../ImageTextTeaser/fragment';
import { ParagraphLinksFragment } from '../Links/fragment';
import { ParagraphMerchantListFragment } from '../MerchantList/fragment';
import { ParagraphNewsListFragment } from '../NewsList/fragment';
import { ParagraphProductListFragment } from '../ProductList/fragment';
import { ParagraphRegionTeaserListFragment } from '../RegionTeaserList/fragment';
import { ParagraphSearchbarFragment } from '../Searchbar/fragment';
import { ParagraphSearchResultsFragment } from '../SearchResults/fragment';
import { ParagraphTestimonialFragment } from '../Testimonial/fragment';
import { ParagraphTextFragment } from '../Text/fragment';

export const ParagraphFromLibraryItemFragment = gql`
  fragment ParagraphFromLibraryItem on ParagraphFromLibraryItem {
    id
    content {
      ...ParagraphTextFragment
      ...ParagraphImageFragment
      ...ParagraphActivityPoiMap
      ...ParagraphActivityPoiPoisInRegion
      ...ParagraphActivityPoiProductsInRegion
      ...ParagraphActivityPoiImages
      ...ParagraphActivityPoiActivitiesInRegion
      ...ParagraphCommentListAndFormular
      ...ParagraphColumnsFragment
      ...ParagraphFormularFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphImageTextTeaserFragment
      ...ParagraphLinksFragment
      ...ParagraphNewsListFragment
      ...ParagraphProductListFragment
      ...ParagraphRegionTeaserListFragment
      ...ParagraphActivityListFragment
      ...ParagraphHotelListFragment
      ...ParagraphMerchantListFragment
      ...ParagraphActivityTabsFragment
      ...ParagraphActivityPoiTeaserListFragment
      ...ParagraphHeroFragment
      ...ParagraphHotelOffersFragment
      ...ParagraphActivitySliderFragment
      ...ParagraphSearchbarFragment
      ...ParagraphTestimonialFragment
      ...ParagraphEyeCatcherFragment
      ...ParagraphAdSlot
    }
  }

  ${ParagraphTextFragment}
  ${ParagraphHotelOffersFragment}
  ${ParagraphMerchantListFragment}
  ${ParagraphActivityTabsFragment}
  ${ParagraphSearchbarFragment}
  ${ParagraphContainerFragment}
  ${ParagraphColumnFragment}
  ${ParagraphTestimonialFragment}
  ${ParagraphImageFragment}
  ${ParagraphEyeCatcherFragment}
  ${ParagraphActivityPoiMapFragment}
  ${ParagraphActivityPoiPoisInRegionFragment}
  ${ParagraphActivityPoiImagesFragment}
  ${ParagraphActivityPoiActivitiesInRegionFragment}
  ${ParagraphActivityPoiProductsInRegionFragment}
  ${ParagraphCommentListAndFormularFragment}
  ${ParagraphActivitySliderFragment}
  ${ParagraphColumnsFragment}
  ${ParagraphActivityListFragment}
  ${ParagraphActivityPoiTeaserListFragment}
  ${ParagraphFormularFragment}
  ${ParagraphHotelListFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphLinksFragment}
  ${ParagraphNewsListFragment}
  ${ParagraphProductListFragment}
  ${ParagraphRegionTeaserListFragment}
  ${ParagraphAdSlotFragment}
`;

export const ParagraphFromLibraryFragment = gql`
  fragment ParagraphFromLibrary on ParagraphFromLibrary {
    id
    paragraph {
      ...ParagraphFromLibraryItem
    }
  }

  ${ParagraphFromLibraryItemFragment}
`;
