import { gql } from '@apollo/client';

export const PartnerFragment = gql`
  fragment PartnerFragment on TermPartner {
    id
    name
    image {
      url
      teaser: imageStyle(name: TEASER_ORIGINAL) {
        url
        width
        height
      }
    }
    text
    externalUrl
  }
`;
