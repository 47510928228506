import { gql } from '@apollo/client';
import {
  ProductFragment,
  ProductTeaserFragment,
} from '../../Node/Product/fragment';

export const ParagraphProductListFragment = gql`
  fragment ParagraphProductListFragment on ParagraphProductList {
    id
    title
    desktopAsSlider
    data {
      items {
        ...ProductTeaserFragment
      }
    }
  }

  ${ProductTeaserFragment}
`;
