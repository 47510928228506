import { gql } from '@apollo/client';

export const ParagraphLinksFragment = gql`
  fragment ParagraphLinksFragment on ParagraphLinks {
    links {
      link {
        url(frontend: $frontend)
        text
      }
      countries {
        id
      }
      regions {
        id
      }
    }
  }
`;
