import { gql } from '@apollo/client';
import { ParagraphActivityPoiTeaserListFragment } from '../../Paragraph/ActivityPoiTeaserList/fragment';
import { ParagraphHotelOffersFragment } from '../../Paragraph/HotelOffers/fragment';
import { ParagraphHeroFragment } from '../../Paragraph/Hero/fragment';
import { ParagraphActivitySliderFragment } from '../../Paragraph/ActivitySlider/fragment';
import { ParagraphActivityListFragment } from '../../Paragraph/ActivityList/fragment';
import { ParagraphMerchantListFragment } from '../../Paragraph/MerchantList/fragment';
import { ParagraphActivityTabsFragment } from '../../Paragraph/ActivityTabs/fragment';
import { ParagraphRegionTeaserListFragment } from '../../Paragraph/RegionTeaserList/fragment';
import { ParagraphTextFragment } from '../../Paragraph/Text/fragment';
import { ParagraphFormularFragment } from '../../Paragraph/Formular/fragment';
import { ParagraphHeaderFragment } from '../../Paragraph/Header/fragment';
import { ParagraphSearchbarFragment } from '../../Paragraph/Searchbar/fragment';
import { ParagraphContainerFragment } from '../../Paragraph/Container/fragment';
import { ParagraphColumnsFragment } from '../../Paragraph/Columns/fragment';
import { ParagraphColumnFragment } from '../../Paragraph/Column/fragment';
import { ParagraphImageTextTeaserFragment } from '../../Paragraph/ImageTextTeaser/fragment';
import { ParagraphHotelListFragment } from '../../Paragraph/HotelList/fragment';
import { ParagraphTestimonialFragment } from '../../Paragraph/Testimonial/fragment';
import { ParagraphSearchResultsFragment } from '../../Paragraph/SearchResults/fragment';
import { ParagraphImageFragment } from '../../Paragraph/Image/fragment';
import { ParagraphImageAndTextFragment } from '../../Paragraph/ImageAndText/fragment';
import { MediaImageFragment, TermCategoryFragment } from '../../../queries';
import { ParagraphProductListFragment } from '../../Paragraph/ProductList/fragment';
import { ParagraphLinksFragment } from '../../Paragraph/Links/fragment';
import { ParagraphFromLibraryFragment } from '../../Paragraph/FromLibrary/fragment';
import { ParagraphCustomTeaserListFragment } from '../../Paragraph/CustomTeaserList/fragment';
import { PersonFragment, PersonTeaserFragment } from '../Person/fragment';
import { PartnerFragment } from '../../../graphql/fragments/Partner';
import { ParagraphInfoBoxFragment } from '../../Paragraph/InfoBox/fragment';
import { ParagraphCommentBoxFragment } from '../../Paragraph/CommentBox/fragment';
import { ParagraphPartnerFragment } from '../../Paragraph/Partner/fragment';
import { ParagraphAuthorsFragment } from '../../Paragraph/Authors/fragment';
import { ParagraphAdSlotFragment } from '../../Paragraph/AdSlot/fragment';
import { ParagraphFaqFragment } from '../../Paragraph/Faq/fragment';

export const NewsFragment = gql`
  fragment NewsFragment on News {
    id
    title
    created
    updatedAt
    url(frontend: $frontend)
    frontend {
      name
    }
    category {
      ...TermCategoryFragment
    }
    metatags(frontend: $frontend) {
      ...MetaTagsFragment
    }
    teaserImage {
      ...MediaImageFragment
      teaser: imageStyle(name: TEASER) {
        url
      }
      teaserLarge: imageStyle(name: TEASER_LARGE) {
        url
      }
      hero: imageStyle(name: HERO_LARGE) {
        url
      }
    }
    content {
      ... on ParagraphInterface {
        id
      }
      __typename
      ...ParagraphHeaderFragment
      ...ParagraphActivityListFragment
      ...ParagraphHotelListFragment
      ...ParagraphMerchantListFragment
      ...ParagraphActivityTabsFragment
      ...ParagraphActivityPoiTeaserListFragment
      ...ParagraphHeroFragment
      ...ParagraphHotelOffersFragment
      ...ParagraphActivitySliderFragment
      ...ParagraphTextFragment
      ...ParagraphFormularFragment
      ...ParagraphRegionTeaserListFragment
      ...ParagraphSearchbarFragment
      ...ParagraphContainerFragment
      ...ParagraphColumnsFragment
      ...ParagraphTestimonialFragment
      ...ParagraphSearchResultsFragment
      ...ParagraphImageFragment
      ...ParagraphImageAndTextFragment
      ...ParagraphProductListFragment
      ...ParagraphLinksFragment
      ...ParagraphFromLibrary
      ...ParagraphCustomTeaserList
      ...ParagraphInfoBox
      ...ParagraphCommentBox
      ...ParagraphPartner
      ...ParagraphAuthors
      ...ParagraphAdSlot
      ...ParagraphFaq
    }

    authors {
      ...PersonTeaserFragment
    }
    partner {
      ...PartnerFragment
    }
  }

  ${ParagraphFaqFragment}
  ${ParagraphAdSlotFragment}
  ${PersonTeaserFragment}
  ${PartnerFragment}
  ${ParagraphPartnerFragment}
  ${ParagraphAuthorsFragment}
  ${ParagraphInfoBoxFragment}
  ${ParagraphCommentBoxFragment}
  ${ParagraphActivityPoiTeaserListFragment}
  ${ParagraphTextFragment}
  ${ParagraphHeaderFragment}
  ${ParagraphHeroFragment}
  ${ParagraphFormularFragment}
  ${ParagraphHotelOffersFragment}
  ${ParagraphActivitySliderFragment}
  ${ParagraphActivityListFragment}
  ${ParagraphHotelListFragment}
  ${ParagraphMerchantListFragment}
  ${ParagraphActivityTabsFragment}
  ${ParagraphRegionTeaserListFragment}
  ${ParagraphSearchbarFragment}
  ${ParagraphContainerFragment}
  ${ParagraphColumnsFragment}
  ${ParagraphColumnFragment}
  ${ParagraphImageTextTeaserFragment}
  ${ParagraphTestimonialFragment}
  ${ParagraphSearchResultsFragment}
  ${ParagraphImageFragment}
  ${ParagraphImageAndTextFragment}
  ${ParagraphProductListFragment}
  ${ParagraphLinksFragment}
  ${ParagraphFromLibraryFragment}
  ${ParagraphCustomTeaserListFragment}
`;
