import { action, makeObservable, observable } from 'mobx';
import { ActiveFilter } from '../generated/types';

export default class Search {
  sort: string | null = 'popularity__desc';
  activeFilters: ActiveFilter[] = [];

  constructor() {
    makeObservable(this, {
      sort: observable,
      activeFilters: observable,
      setSort: action,
      setActiveFilters: action,
    });
  }

  setSort(sort: string) {
    this.sort = sort;
  }

  setActiveFilters(filters: ActiveFilter[]) {
    this.activeFilters = filters;
  }
}
